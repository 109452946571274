<template>
  <div class="wrapper">
    <div id="adngin-adhesive-0"></div>
  </div>
</template>

<script>
import { activateAdUnit } from "../../utils/ads";

export default {
  name: "MobileAdhesiveAd",
  inject: ["isAdScriptLoaded"],
  watch: {
    isAdScriptLoaded: {
      handler(isLoaded) {
        if (!isLoaded) return;
        if (window.innerWidth > 1024) return;
        activateAdUnit("adhesive");
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
@media (min-width: 1024px) {
  .wrapper {
    display: none;
  }
}
</style>
