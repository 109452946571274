<template>
  <div class="show-answer-board">
    <div class="board">
      <!-- Column categories -->
      <div class="row" style="display: flex; align-items: flex-end;">
        <div
          v-for="(column, colIndex) in gameData.columns"
          :key="'col-' + colIndex"
          class="category"
        >
          {{ column.name }}
        </div>
      </div>
      <!-- Row categories and filled board -->
      <div v-for="(row, rowIndex) in filledBoard" :key="rowIndex" class="row">
        <!-- Row category -->
        <div class="category row-category">{{ gameData.rows[rowIndex].name }}</div>
        <!-- Filled board cells -->
        <div v-for="(box, colIndex) in row" :key="colIndex" class="box" :class="{'disabled' : !box.guesses.length}"
          @click="showAnswersPopup(box.guesses, gameData.columns[colIndex], gameData.rows[rowIndex])">
          <div class="cell-content">
            <!-- Show Answers text -->
            <div v-if="box.guesses.length"  class="show-answers-text">Show Answers ({{ box.guesses.length }})</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Answers Popup -->
    <div v-if="showPopup" class="answers-popup" @click="closeOnOutsideClick">
      <div class="popup-content">
        <h2>Wrong Answers</h2>
        <div class="popup-subtitle">Here is the relevant data we have on file for your answers</div>
        <ul>
          <li v-for="(answer, index) in currentAnswers" :key="index">
            <div class="country-title">
              <img
                 :src="getCountryFlagSvgUrl(answer.countryCode)"
                 alt="Country Flag"
                 class="country-flag"
               />
               <h3 class="country-name">{{ answer.countryName }}</h3>
            </div>
            <div>{{ answer.columnReasoning }}</div>
            <div>{{ answer.rowReasoning }}</div>
          </li>
        </ul>
        <button class="close-btn" @click="hideAnswersPopup">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getCountryData, getCountryGeoGridData, getCountryFlagSvgUrl  } from '@/utils/fetchDataFromCDN'
import { getExplanationText } from '@/utils/wrongAnswersUtils'

export default {
  props: {
    board: Array,
    gameData: Object,
    wrongGuesses: Array,
  },
  data() {
    return {
      showPopup: false,
      currentAnswers: [],
      boxIndex: null,
      loading: false,
    };
  },
  computed: {
    // Filled board with guesses for each box
    filledBoard() {
      return this.board.map((row, rowIndex) => {
        return row.map((cell, colIndex) => {
          const matchBox = (rowIndex * this.board[rowIndex].length) + colIndex + 1;
          return {
            guesses: this.wrongGuesses.filter((g) => g.index === matchBox)
          };
        });
      });
    }
  },
  methods: {
    getCountryFlagSvgUrl,
    // Show answers popup
    async showAnswersPopup(answers, colCategory, rowCategory) {
      if (this.loading) return;

      this.loading = true;

      // fetch country data for each answer
      const promises = [];
      for (const answer of answers) {
        promises.push(this.fetchCountryData(answer.code, answer.name))
      }
      const allCountryData = await Promise.all(promises);

      // get the reasoning so each country being wrong
      const formattedText = [];
      for (const country of allCountryData) {
        console.log(country)
        const [
          columnReasoning,
          rowReasoning
        ] = await Promise.all([
          getExplanationText(country, colCategory),
          getExplanationText(country, rowCategory)
        ]);

        formattedText.push({
          countryName: country.countryName,
          countryCode: country.countryCode,
          columnReasoning,
          rowReasoning
        });
      }

      this.currentAnswers = formattedText;
      this.loading = false;
      this.showPopup = true;
    },
    // Hide answers popup
    hideAnswersPopup() {
      this.showPopup = false;
      this.currentAnswers = [];
    },
    // Close answers popup on click outside
    closeOnOutsideClick(event) {
      if (!event.target.closest('.popup-content')) {
        this.hideAnswersPopup();
      }
    },
    async fetchCountryData(countryCode, countryName) {
      const [
        countryData,
        geoGridData
      ] = await Promise.all([
        getCountryData(countryCode),
        getCountryGeoGridData(countryCode)
      ]);

      return { countryCode, countryName, countryData, geoGridData }
    }
  }
};
</script>

<style scoped>

.show-answer-board {
  display: flex;
  justify-content: center;
}

.board {
  display: flex;
  flex-direction: column;
  margin-left: 120px;
  gap: 0;
}

.row {
  display: flex;
  align-items: center;
  gap: 5px;
}

.row:not(:first-child) {
    margin-bottom: -5px;
  }

.row:first-child{
  margin-left: 145px;
}

.category {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 140px;
  min-height: 50px;
  max-height: 140px;
  margin-top: 5px;
  color: rgb(223, 223, 223);
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px;
  box-sizing: border-box;
  border-radius: 6px;
}

.box {
  width: 140px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid transparent;
  border-radius: 6px;
  cursor: pointer;
  box-sizing: border-box;
}

.box.disabled:hover {
  cursor: auto;
  background-color: rgba(0, 0, 0, 0.2);
}

.cell-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -16px;
}

.show-answers-text {
  padding: 10px;
  font-size: 14px;
  color: #fff;
  margin-top:15px;
  font-weight: bold;
}

.answers-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup-content {
  background-color: rgb(53, 53, 72);
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  color: white;
  padding-bottom: 30px;
  height: auto;
  max-height: 65%;
  overflow-y: auto;
  max-width: 350px;
}

.popup-content h2 {
  color: white;
  margin-bottom: 0px;
}

.popup-content .popup-subtitle {
  color: white;
  padding: 0;
  font-size: 12px;
  margin-bottom: 16px;
  line-height: 1rem;
}

.popup-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.popup-content ul li {
  text-align: start;
  margin-bottom: 10px;
  gap: 8px;
}

.popup-content button {
  margin-top: 10px;
}

.country-name {
  margin: 0;
}

.country-title {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
  margin-bottom: 4px;
}

.country-flag {
  width: 30px;
  height: auto;
  max-height: 30px;
}

.close-btn {
  padding: 6px 12px;
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.5);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
}
.close-btn:hover {
  background-color: rgb(223, 223, 223);
}

@media (max-width: 992px) {
  .board{
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .board {
    display: flex;
    flex-direction: column;
    margin-left: -273px;
  }

  .row {
    margin-left: 45px;
    gap: 4px;
  }
  .row:not(:first-child) {
    margin-bottom: -11px;
  }
  .row:first-child {
    margin-left: 129px;
    margin-bottom: -3px;
  }
  .category {
    width: 105px;
    font-size: 10px;
    min-height: 40px;
    max-height: 105px;
    margin-top: 8px;
  }
  .row-category{
    width: 80px;
  }

  .box {
    width: 105px;
    height: 105px;
  }

  .cell-content {
    margin-top: -8px;
  }
  
  .percentage {
    margin-top: 4px;
    font-size: 12px;
    color: white;
    font-weight: bold;
    line-height: 20px;
    position: relative;
    top: 2px;
  }

  .show-answers-text {
    font-size: 10px;
  }

  .popup-content {
    max-width: 80%;
    max-height: 50%;
    overflow-y: auto;
  }

  .country-flag {
    width: 20px;
    max-height: 20px;
  }
}

@media (max-width: 480px) {
  .board {
    display: flex;
    flex-direction: column;
    margin-left: -65px;
  }
  .row {
    margin-left: 55px;
  }
  .row:first-child {
    margin-left: 115px;
    margin-bottom: 2px;
  }
  .row:not(:first-child) {
    margin-bottom: -1px;
  }
  .category {
    width: 85px;
    font-size: 8px;
    min-height: 30px;
    max-height: 85px;
    margin-top: 3px;
  }
  .row-category{
    width: 56px;
  }

  .box {
    width: 85px;
    height: 85px;
  }

  .cell-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -8px;
    position: relative;
  }
  
  .show-answers-text {
    font-size: 8px;
  }

  .popup-content {
    max-width: 60%;
    max-height: 60%;
    font-size: 12px;
    overflow-y: auto;
  }
}

@media (max-width: 375px) {
  .row:first-child {
    margin-bottom: 7px;
  }
  .row:not(:first-child) {
    margin-bottom: 10px;
  }
  .row-category{
    height: 85px;
    margin-top: -2px;
  }
}

@media (max-width: 768px), (max-width: 480px) {
  body {
    overflow: hidden;
  }
}

</style>