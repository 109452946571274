<template>
  <div class="icon-row">
      <a href="https://www.facebook.com/teuteufgames/" target="_blank" rel="noreferrer">
        <img src="/facebook.png" alt="facebook" class="icon"  />
      </a>
      <a href="https://www.tiktok.com/@teuteufgames" target="_blank" rel="noreferrer">
        <img src="/tiktok.svg" alt="tiktok" class="icon" />
      </a>
      <a href="https://www.instagram.com/teuteufgames" target="_blank" rel="noreferrer">
        <img src="/instagram.png" alt="instagram" class="icon" />
      </a>
    </div>
</template>

<script>
export default {
  name: 'SocialLinks',
};
</script>

<style scoped>
.icon-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 12px;
}

.icon {
  width: 32px;
  height: 32px;
}
</style>