<template>
  <div class="wrapper">
    <div id="adngin-mobile_mrec-0"></div>
  </div>
</template>

<script>
import { activateAdUnit } from "../../utils/ads";

export default {
  name: "MobileMrecAd",
  inject: ["isAdScriptLoaded"],
  watch: {
    isAdScriptLoaded: {
      handler(isLoaded) {
        console.log({ isLoaded });
        if (!isLoaded) return;
        if (window.innerWidth > 1024) return;
        console.log("activate mobile_mrec");
        activateAdUnit("mobile_mrec");
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.wrapper {
  min-height: 50px;
  margin: 1rem 0.2rem;
}
@media (min-width: 1024px) {
  .wrapper {
    display: none;
  }
}
</style>
