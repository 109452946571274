import axios from "axios";

export function getServerUrl() {
  if (typeof window !== "undefined") {
    if (
      window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1"
    ) {
      return "http://localhost:3000";
    } else {
      return "https://auth.teuteuf.fr";
    }
  }
}

export async function getData(key) {
  const token = localStorage.getItem("token");
  if (!token) {
    return null;
  }
  try {
    const response = await axios.post(
      `${getServerUrl()}/api/getdata`,
      {
        gameId: "geogrid",
        key,
      },
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    if (response.data?.value) {
      return response.data.value;
    }
  } catch (e) {
    console.error("Error fetching data", e);
  }
  return null;
}

export async function setData(key, value) {
  const token = localStorage.getItem("token");
  if (!token) {
    return false;
  }
  try {
    const response = await axios.post(
      `${getServerUrl()}/api/setdata`,
      {
        gameId: "geogrid",
        key,
        value,
      },
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    if (response.data?.value) {
      return true;
    }
  } catch (e) {
    console.error("Error saving data", e);
  }
  return false;
}
