import { createRouter, createWebHistory } from 'vue-router';
import mainBoard from '../components/mainBoard.vue';
import loggedIn from '../components/loggedIn.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'mainBoard',
            component: mainBoard
        },
        {
            path: '/board/:board_id',
            name: 'board',
            component: mainBoard
        },
        {
            path: '/logged-in',
            name: 'loggedIn',
            component: loggedIn
        },
    ]
});

export default router;