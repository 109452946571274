<template>
  <div id="app">
    <RouterView :key="key" />
  </div>
</template>

<script>
import { ref, provide } from "vue";
import { RouterView } from "vue-router";
import { useFavicon } from "@vueuse/core";
import { loadAdScript } from "./utils/ads";
import axios from "axios";
import { getData, getServerUrl, setData } from "./utils/server";

export default {
  name: "App",
  components: {
    RouterView,
  },
  data () {
    return {
      key: 0,
    }
  },
  setup() {
    const icon = useFavicon();
    icon.value = "/earth-emoji.webp";

    const userDetails = ref(null);
    const isAdScriptLoaded = ref(false);

    provide("userDetails", userDetails);
    provide("isAdScriptLoaded", isAdScriptLoaded);

    return {
      userDetails,
      isAdScriptLoaded,
    };
  },
  async mounted() {
    // Try to log user in
    let shouldLoadAds = true;
    if (localStorage.getItem("token")) {
      await this.refreshTokens();
      const user = await this.getUserDetails();
      this.userDetails = user;
      if (user?.premiumGames?.includes("geogrid")) {
        shouldLoadAds = false;
      }
    }
    if (shouldLoadAds) {
      loadAdScript(() => {
        this.isAdScriptLoaded = true;
      });
    }

    if (this.userDetails) {
      const serverData = (await getData("userData")) ?? {};
      const userDataString = localStorage.getItem("userData");
      let userData = {};
      let localChanged = false;
      let shouldUpload = false;
      try {
        if (userDataString) {
          userData = JSON.parse(userDataString);
        }
      } catch (e) {
        console.error("Error decoding user data", e);
      }

      for (const date in userData) {
        if (!(date in serverData)) {
          shouldUpload = true;
        }
      }

      if (serverData) {
        // Merge serverData into userData
        for (const date in serverData) {
          if (!(date in userData)) {
            userData[date] = serverData[date];
            localChanged = true;
          }
        }
      }

      // Set userData to localStorage
      if (localChanged) {
        localStorage.setItem("userData", JSON.stringify(userData));
        this.key++;
      }

      // Upload userData to server
      if (shouldUpload) {
        await setData("userData", userData);
      }
    }
  },
  methods: {
    async refreshTokens() {
      let refreshTime = localStorage.getItem("refreshTime");
      if (refreshTime) {
        const refreshDate = new Date(+refreshTime);
        const today = new Date();
        if (
          refreshDate.getDate() === today.getDate() &&
          refreshDate.getMonth() === today.getMonth() &&
          refreshDate.getFullYear() === today.getFullYear()
        ) {
          console.log("Tokens already refreshed today");
          return;
        }
      }

      const token = localStorage.getItem("token");
      const refreshToken = localStorage.getItem("refreshToken");
      if (!token || !refreshToken) {
        console.error("No token or refresh token found");
        return;
      }

      const response = await axios.post(`${getServerUrl()}/auth/refresh`, {
        token,
        refreshToken,
      });
      try {
        if (response?.data) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("refreshToken", response.data.refreshToken);
          localStorage.setItem("refreshTime", Date.now());
        }
      } catch (e) {
        console.error("Could not refresh tokens", e);
      }
    },
    async getUserDetails() {
      const token = localStorage.getItem("token");
      if (!token) {
        return null;
      }

      const response = await axios.get(`${getServerUrl()}/api/getuser`, {
        headers: { Authorization: "Bearer " + token },
      });
      try {
        if (response?.data) {
          return response.data;
        }
      } catch (e) {
        console.error("Could not get user details", e);
      }
      return null;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@400;500;600;700&display=swap");

#app {
  font-family: "Oxygen", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  line-height: 1.6;
}

body {
  margin: 0;
}
</style>
