<template>
  <div class="accordian-panel">
    <div class="accordian-header" @click="$emit('toggle')">
      <span class="chevron" :class="{ 'chevron-up': !isActive }">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" stroke-width="1"
          stroke="currentColor" viewBox="0 0 16 16">
          <path fill-rule="evenodd"
            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
        </svg>
      </span>
      {{ categorySection.name }}
    </div>
    <transition name="slide-fade">
      <div v-if="isActive" class="accordian-content">
        <p v-for="(category, catIndex) in categorySection.categories" :key="catIndex" @click="$emit('select', category)"
          :class="{ 'selected': category.name === selectedCategory?.name, 'accordian-content-item': true }">
          {{ category.name }}
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'CategoryListSection',
  props: {
    categorySection: Object,
    isActive: Boolean,
    selectedCategory: Object
  }
};
</script>

<style scoped>
.accordian-panel {
  padding: 0;
  border: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: start;
  align-items: start;
}

.accordian-header {
  display: flex;
  flex-grow: 1;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  color: white;
  padding: 4px;
  border: none;
  cursor: pointer;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
    max-width: 100%;
  text-align: left;
  border-radius: 8px;
}

.accordian-header:hover {
  background: #414158;
}
.chevron {
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 1.6rem;
  transition: transform 0.3s ease;
  transform: rotate(180deg);
}

.chevron-up {
  transform: rotate(0deg);
}

.accordian-content {
  display: flex;
    flex-direction: column;
    width: 100%;
  }

  .accordian-content-item {
    display: flex;
    flex-grow: 1;
  font-size: 1rem;
  color: #C8C8D4;
  cursor: pointer;
  padding: 8px;
  padding-left: 20px;
  margin: 0;
  border-radius: 8px;
  width: 100%;
  text-align: left;
  box-sizing: border-box;
  max-width: 100%;
  overflow-wrap: break-word;
}

.accordian-content-item.selected {
  background: #252641;
  color: white;
  font-weight: 500;
}

.accordian-content-item:hover {
  background: #414158;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  max-height: 0;
  opacity: 0;
  transform: translateY(-10px);
}

.slide-fade-enter-to,
.slide-fade-leave-from {
  max-height: 500px;
  opacity: 1;
  transform: translateY(0);
}
</style>