const section1 = {
  name: 'Flag',
  categories: [
    {
      ids: ['color_on_flag'],
      name: 'Flag with color',
      description: [
        'Color must appear on the flag.',
        'Accepted Colors: Only the following colors are recognized: black, white, grey, pink, red, orange, yellow, green, blue, light blue, purple, brown. Any other shades will be categorized under the closest matching color on this list.',
        'Shades Don\'t Count Separately: Different shades of the same color (e.g., dark red or pale yellow) do not count as separate colors. They will all be classified under the primary color (e.g., "red" or "yellow").',
        'Exception for Blue: If a flag contains only one blue, it will be classified as "blue." If there are two blues, and one is significantly lighter, the colors will be classified as "blue" and "light blue."',
        'Counting Colors: The total number of colors on the flag will only reflect these accepted colors, with no distinction made for minor shade variations (except for the exception mentioned with blue).',
      ],
      sources: [],
    },
    {
      ids: ['flag_has_star'],
      name: 'Star in flag',
      description: [
        'Has/Does not have a star in the flag, including symbols of the sun.',
      ],
      sources: [
        {
          name: 'Wikipedia - National Flags with a Sun',
          link: 'https://en.wikipedia.org/wiki/List_of_national_flags_by_design#Sun',
        },
        {
          name: 'Wikipedia - National Flags with a Star',
          link: 'https://en.wikipedia.org/wiki/List_of_national_flags_by_design#Star',
        },
      ],
    },
    {
      ids: ['flag_rwb'],
      name: 'Flag with only red, white, and blue',
      description: [
        'Flag consists of only red, white, and blue.',
        'Accepted Colors: Only the following colors are recognized: black, white, grey, pink, red, orange, yellow, green, blue, light blue, purple, brown. Any other shades will be categorized under the closest matching color on this list.',
        'Shades Don\'t Count Separately: Different shades of the same color (e.g., dark red or pale yellow) do not count as separate colors. They will all be classified under the primary color (e.g., "red" or "yellow").',
        'Exception for Blue: If a flag contains only one blue, it will be classified as "blue." If there are two blues, and one is significantly lighter, the colors will be classified as "blue" and "light blue."',
        'Counting Colors: The total number of colors on the flag will only reflect these accepted colors, with no distinction made for minor shade variations (except for the exception mentioned with blue).',
      ],
      sources: [],
    },
    {
      ids: ['flag_without_rwb'],
      name: 'Flag without red, white, or blue',
      description: [
        'Flag does not consist of red, white, or blue.',
        'Accepted Colors: Only the following colors are recognized: black, white, grey, pink, red, orange, yellow, green, blue, light blue, purple, brown. Any other shades will be categorized under the closest matching color on this list.',
        'Shades Don\'t Count Separately: Different shades of the same color (e.g., dark red or pale yellow) do not count as separate colors. They will all be classified under the primary color (e.g., "red" or "yellow").',
        'Exception for Blue: If a flag contains only one blue, it will be classified as "blue." If there are two blues, and one is significantly lighter, the colors will be classified as "blue" and "light blue."',
        'Counting Colors: The total number of colors on the flag will only reflect these accepted colors, with no distinction made for minor shade variations (except for the exception mentioned with blue).',
      ],
      sources: [],
    },
    {
      ids: ['flag_has_coat_of_arms'],
      name: 'Flag has a coat of arms',
      description: [
        'The flag has a coat of arms, national emblem, or national seal.',
        "Even though the design of an emblem doesn't follow the rules of heraldry and so not containing a shield (escutcheon) in the center, we decided to include them in this category. Many unheraldic national emblems are colloquially called national coats of arms anyway, because they are used for the same purposes as national coats of arms.",
      ],
      sources: [
        {
          name: 'Wikipedia - Flags with coats of arms',
          link: 'https://en.wikipedia.org/wiki/List_of_national_flags_by_design#Coat_of_arms',
        },
        {
          name: 'Wikipedia - National emblems',
          link: 'https://en.wikipedia.org/wiki/National_emblem',
        },
      ],
    },
    {
      ids: ['flag_has_animal'],
      name: 'Flag has an animal',
      description: [
        'Flag contains a depiction of an animal (including humans).',
      ],
      sources: [
        {
          name: 'Wikipedia - Flags with animals',
          link: 'https://en.wikipedia.org/wiki/List_of_national_flags_by_design',
        },
      ],
    },
    {
      ids: ['flag_has_x_colors', 'flag_has_x_or_more_colors'],
      name: 'Flag with only # colors',
      description: [
        'Country with a flag consisting of only a specified number of colors.',
        'Accepted Colors: Only the following colors are recognized: black, white, grey, pink, red, orange, yellow, green, blue, light blue, purple, brown. Any other shades will be categorized under the closest matching color on this list.',
        'Shades Don\'t Count Separately: Different shades of the same color (e.g., dark red or pale yellow) do not count as separate colors. They will all be classified under the primary color (e.g., "red" or "yellow").',
        'Exception for Blue: If a flag contains only one blue, it will be classified as "blue." If there are two blues, and one is significantly lighter, the colors will be classified as "blue" and "light blue."',
        'Counting Colors: The total number of colors on the flag will only reflect these accepted colors, with no distinction made for minor shade variations (except for the exception mentioned with blue).',
      ],
      sources: [],
    },
  ],
};

const section2 = {
  name: 'Geography',
  categories: [
    {
      ids: ['island_nation'],
      name: 'Island nation',
      description: [
        'The country is entirely surrounded by water and does not share land borders with any other country, being composed of one or more islands.',
        'Exceptions:',
        'Australia is not included as it is considered a continental country, while Greenland is regarded as the largest island.',
        'Even though the following countries de facto have a land border with another country, we include them in this category based on their geographical location, being situated on an island without mainland territory: Brunei, Dominican Republic, Haiti, Indonesia, Ireland, Papua New Guinea, Timor-Leste, Sint Maarten, Saint Martin.',
      ],
      sources: [
        {
          name: 'Wikipedia - List of island countries',
          link: 'https://en.wikipedia.org/wiki/List_of_island_countries',
        },
      ],
    },
    {
      ids: ['landlocked'],
      name: 'Landlocked',
      description: [
        'Does not border an ocean or a sea. The Caspian Sea is not considered a sea, so, for example, Turkmenistan is a landlocked country.',
      ],
      sources: [
        {
          name: 'Wikipedia - List of landlocked countries',
          link: 'https://en.wikipedia.org/wiki/Landlocked_country#List_of_landlocked_countries_and_landlocked_de_facto_states',
        },
      ],
    },
    {
      ids: ['coastline_length_over_x', 'coastline_length_under_x'],
      name: 'Coastline length',
      description: [
        'Over or under specified length of coastline. Excludes overseas departments. For countries where data is not available from our listed source, the World Resources Institute, we have used an alternative source, The World Factbook.',
      ],
      stats: [
        '25th percentile: 72 km',
        '50th percentile: 445 km',
        '75th percentile: 2,400 km',
        'Average: 3,800 km',
      ],
      sources: [
        {
          name: 'World Resources Institute',
          link: 'https://en.wikipedia.org/wiki/List_of_countries_by_length_of_coastline',
        },
      ],
    },
    {
      ids: ['coastline_on_x'],
      name: 'Coastline on the X',
      subCategories: [
        {
          description: ['Has a coastline on the Mediterranean Sea.'],
          sources: [
            {
              name: 'Wikipedia - Mediterranean Sea',
              link: 'https://en.wikipedia.org/wiki/Mediterranean_Sea',
            },
          ],
        },
        {
          description: [
            "Has a coastline on the Indian Ocean. Countries are considered to have a coastline on an ocean if they directly border that ocean or any adjacent seas or gulfs that are not separated by significant geographical barriers (like straits). For example, Oman has a coastline on the Indian Ocean despite bordering the Arabian Sea, but Qatar doesn't have a coastline on the Indian Ocean because the Persian Gulf is separated from the Gulf of Oman, the Arabian Sea and therefore the Indian Ocean by the Strait of Hormuz.",
          ],
          sources: [
            {
              name: 'Wikipedia - Indian Ocean',
              link: 'https://en.wikipedia.org/wiki/Indian_Ocean',
            },
          ],
        },
        {
          description: [
            'Has a coastline on the Pacific Ocean. Countries are considered to have a coastline on an ocean if they directly border that ocean or any adjacent seas or gulfs that are not separated by significant geographical barriers (like straits).',
            'Seas in the Pacific Ocean which are treated differently: the South China Sea and the East China Sea. Countries bordering these seas (e.g., Brunei, Vietnam) are classified as having coastlines on the South or East China Seas, not the Pacific Ocean.',
          ],
          sources: [
            {
              name: 'Wikipedia - Pacific Ocean',
              link: 'https://en.wikipedia.org/wiki/Pacific_Ocean',
            },
          ],
        },
        {
          description: [
            "Has a coastline on the Atlantic Ocean (both South and North Atlantic). Countries are considered to have a coastline on an ocean if they directly border that ocean or any adjacent seas or gulfs that are not separated by significant geographical barriers (like straits). For example, Algeria doesn't have a coastline on the Atlantic Ocean.",
            'Seas in the Atlantic Ocean which are treated differently: the Caribbean Sea. Countries bordering the Caribbean Sea (e.g., Panama, Colombia) are classified as having coastlines on the Caribbean Sea, not the Atlantic Ocean.',
          ],
          sources: [
            {
              name: 'Wikipedia - Atlantic Ocean',
              link: 'https://en.wikipedia.org/wiki/Atlantic_Ocean',
            },
          ],
        },
        {
          description: [
            'Has a coastline on the South Atlantic Ocean. The same rules are applied as to the whole Atlantic Ocean.',
          ],
          sources: [
            {
              name: 'Wikipedia - South Atlantic Ocean',
              link: 'https://en.wikipedia.org/wiki/List_of_seas_on_Earth',
            },
          ],
        },
        {
          description: [
            'Has a coastline on the North Atlantic Ocean. The same rules are applied as to the whole Atlantic Ocean.',
          ],
          sources: [
            {
              name: 'Wikipedia - North Atlantic Ocean',
              link: 'https://en.wikipedia.org/wiki/List_of_seas_on_Earth',
            },
          ],
        },
        {
          description: ['Has a coastline on the Caribbean Sea.'],
          sources: [
            {
              name: 'Wikipedia - Caribbean Sea',
              link: 'https://en.wikipedia.org/wiki/Caribbean_Sea',
            },
          ],
        },
        {
          description: ['Has a coastline on the Black Sea.'],
          sources: [
            {
              name: 'Wikipedia - Black Sea',
              link: 'https://en.wikipedia.org/wiki/Black_Sea',
            },
          ],
        },
        {
          description: ['Has a coastline on the Baltic Sea.'],
          sources: [
            {
              name: 'Wikipedia - Baltic Sea.',
              link: 'https://en.wikipedia.org/wiki/Baltic_Sea',
            },
          ],
        },
        {
          description: ['Has a coastline on the South or East China Seas.'],
          sources: [
            {
              name: 'Wikipedia - East China Sea',
              link: 'https://en.wikipedia.org/wiki/East_China_Sea',
            },
            {
              name: 'Wikipedia - South China Sea',
              link: 'https://en.wikipedia.org/wiki/South_China_Sea',
            },
          ],
        },
      ],
    },
    {
      ids: ['river_x_runs_through'],
      name: 'Is part of the X River system',
      subCategories: [
        {
          description: ['Is part of the Nile River system.'],
          sources: [
            {
              name: 'Wikipedia - List of River Systems',
              link: 'https://en.wikipedia.org/wiki/List_of_river_systems_by_length',
            },
          ],
        },
        {
          description: ['Is part of the Amazon River system.'],
          sources: [
            {
              name: 'Wikipedia - List of River Systems',
              link: 'https://en.wikipedia.org/wiki/List_of_river_systems_by_length',
            },
          ],
        },
        {
          description: ['Is part of the Danube River system.'],
          sources: [
            {
              name: 'Wikipedia - List of River Systems',
              link: 'https://en.wikipedia.org/wiki/List_of_river_systems_by_length',
            },
          ],
        },
        {
          description: ['Is part of the Congo River system.'],
          sources: [
            {
              name: 'Wikipedia - List of River Systems',
              link: 'https://en.wikipedia.org/wiki/List_of_river_systems_by_length',
            },
          ],
        },
        {
          description: ['Is part of the Niger River system.'],
          sources: [
            {
              name: 'Wikipedia - List of River Systems',
              link: 'https://en.wikipedia.org/wiki/List_of_river_systems_by_length',
            },
          ],
        },
        {
          description: ['Is part of the Rhine River system.'],
          sources: [
            {
              name: 'Wikipedia - List of River Systems',
              link: 'https://en.wikipedia.org/wiki/List_of_river_systems_by_length',
            },
          ],
        },
        {
          description: ['Is part of the Mekong River system.'],
          sources: [
            {
              name: 'Wikipedia - List of River Systems',
              link: 'https://en.wikipedia.org/wiki/List_of_river_systems_by_length',
            },
          ],
        },
        {
          description: ['Is part of the Zambezi River system.'],
          sources: [
            {
              name: 'Wikipedia - List of River Systems',
              link: 'https://en.wikipedia.org/wiki/List_of_river_systems_by_length',
            },
          ],
        },
      ],
    },
    {
      ids: ['continent'],
      name: 'In X Continent',
      subCategories: [
        {
          description: ['Is located in Asia.'],
          sources: [
            {
              name: 'Wikipedia - Asia',
              link: 'https://en.wikipedia.org/wiki/Asia',
            },
          ],
        },
        {
          description: ['Is located in Europe.'],
          sources: [
            {
              name: 'Wikipedia - Europe',
              link: 'https://en.wikipedia.org/wiki/Europe',
            },
          ],
        },
        {
          description: ['Is located in Africa.'],
          sources: [
            {
              name: 'Wikipedia - Africa',
              link: 'https://en.wikipedia.org/wiki/Africa',
            },
          ],
        },
        {
          description: ['Is located in North America.'],
          sources: [
            {
              name: 'Wikipedia - North America',
              link: 'https://en.wikipedia.org/wiki/North_America',
            },
          ],
        },
        {
          description: ['Is located in South America.'],
          sources: [
            {
              name: 'Wikipedia - South America',
              link: 'https://en.wikipedia.org/wiki/South_America',
            },
          ],
        },
        {
          description: ['Is located in Oceania.'],
          sources: [
            {
              name: 'Wikipedia - Oceania',
              link: 'https://en.wikipedia.org/wiki/Oceania',
            },
          ],
        },
      ],
    },
    {
      ids: ['touches_eurasian_steppe'],
      name: 'Touches the Eurasian Steppe',
      description: [
        'The country lies within or borders the Eurasian Steppe, a vast grassland region stretching from Eastern Europe to Central Asia.',
      ],
      sources: [
        {
          name: 'Wikipedia - Eurasian Steppe',
          link: 'https://en.wikipedia.org/wiki/Eurasian_Steppe',
        },
      ],
    },
    {
      ids: ['touches_equator'],
      name: 'Touches the Equator',
      description: [
        "The country is situated on or crosses the Earth's equator, the imaginary line that divides the planet into the Northern and Southern Hemispheres.",
      ],
      sources: [
        {
          name: 'Wikipedia - Equator',
          link: 'https://en.wikipedia.org/wiki/Equator#Equatorial_countries_and_territories',
        },
      ],
    },
    {
      ids: ['touches_sahara'],
      name: 'Touches the Sahara Desert',
      description: [
        "The country is located within or borders the Sahara Desert, the world's largest hot desert, spanning much of North Africa.",
      ],
      sources: [
        {
          name: 'Wikipedia - Sahara Desert',
          link: 'https://en.wikipedia.org/wiki/Sahara',
        },
      ],
    },
  ],
};

const section3 = {
  name: 'Economic',
  categories: [
    {
      ids: ['hdi_over_x', 'hdi_under_x'],
      name: 'Human Development Index',
      description: [
        'Over or under specified Human Development Index.',
        'The HDI is a summary measure of achievement in key dimensions: health, knowledge, and living standards.',
        "For countries not listed in the source below, the data was taken from the information box on that country's Wikipedia page. The data is retrieved from the Wikipedia pages every 1st of each month.",
      ],
      sources: [
        {
          name: 'UNDP - HDI Data',
          link: 'https://hdr.undp.org/data-center/country-insights#/ranks',
        },
      ],
    },
    {
      ids: ['gdp_per_capita_over_x', 'gdp_per_capita_under_x'],
      name: 'GDP per capita',
      description: [
        'Over or under specified GDP per capita.',
        'GDP measures economic output per person.',
        "The data was taken from the information box on each country's Wikipedia page. The data is retrieved from the Wikipedia pages every 1st of each month.",
      ],
      sources: [],
    },
    {
      ids: ['nuclear_power'],
      name: 'Produces Nuclear power',
      description: [
        'The country generates electricity using nuclear reactors as part of its energy production.',
      ],
      sources: [
        {
          name: 'Wikipedia - Nuclear power by country',
          link: 'https://en.wikipedia.org/wiki/Nuclear_power_by_country',
        },
      ],
    },
    {
      ids: ['top_20_wheat_production'],
      name: 'Top 20 in wheat production',
      description: [
        'The country is among the top 20 global producers of wheat, measured by annual yield.',
      ],
      sources: [
        {
          name: 'Wikipedia - List of countries by wheat production',
          link: 'https://en.wikipedia.org/wiki/List_of_countries_by_wheat_production',
        },
      ],
    },
    {
      ids: ['top_20_oil_production'],
      name: 'Top 20 in oil production',
      description: [
        'The country is among the top 20 global producers of oil, based on annual crude oil and lease condensate production as compiled by the U.S. Energy Information Administration. This ranking reflects consistent full-year production figures, which avoid short-term distortions due to maintenance or seasonal factors.',
      ],
      sources: [
        {
          name: 'Wikipedia - List of countries by oil production',
          link: 'https://en.wikipedia.org/wiki/List_of_countries_by_oil_production',
        },
      ],
    },
    {
      ids: ['top_20_renewable_electricity_production'],
      name: 'Top 20 in domestic renewable energy share',
      description: [
        'The country is among the top 20 producers of electricity from renewable sources, based on the percentage of renewable energy in domestic electricity production. This includes hydro, wind, biomass, solar, and geothermal energy.',
      ],
      sources: [
        {
          name: 'Wikipedia - List of countries by renewable electricity production',
          link: 'https://en.wikipedia.org/wiki/List_of_countries_by_renewable_electricity_production',
        },
      ],
    },
  ],
};

const section4 = {
  name: 'Name',
  categories: [
    {
      ids: ['starting_letter', 'ending_letter'],
      name: 'Starts/Ends with letter',
      description: ['Starts or ends with a specified letter.'],
    },
    {
      ids: ['name_length', 'name_x_plus_letters_long'],
      name: 'Name is # letters long',
      description: ['Name is a specified number of letters long.'],
    },
    {
      ids: ['name_multiple_words'],
      name: 'Name is multiple words',
      description: ['Name consists of multiple words.'],
    },
    {
      ids: ['name_start_end_same_letter'],
      name: 'Starts and ends with the same letter',
      description: ['The first and the last letters of the name are the same.'],
    },
  ],
};

const section5 = {
  name: 'Borders',
  categories: [
    {
      ids: ['borders_x_to_y', 'borders_x_or_more'],
      name: 'Borders countries',
      description: [
        'Borders a specified number of countries.',
        'For example, in the category "Borders 4-5 countries" Ukraine would be an invalid answer, as it borders 7 countries. The correct answer must fall within the specified range for the category. So, Romania (borders 5 countries) or Czechia (borders 4 countries) would be valid answers. Does not include overseas departments as they are represented as separate entities in our games.',
      ],
      sources: [
        {
          name: 'Wikipedia - Countries and territories by number of land borders',
          link: 'https://en.wikipedia.org/wiki/List_of_countries_and_territories_by_number_of_land_borders',
        },
      ],
    },
    {
      ids: ['borders_x'],
      name: 'Borders X Country',
      subCategories: [
        {
          description: ['Has a land border with Russia.'],
          sources: [
            {
              name: 'Wikipedia - Countries and territories by number of land borders',
              link: 'https://en.wikipedia.org/wiki/List_of_countries_and_territories_by_number_of_land_borders',
            },
          ],
        },
        {
          description: ['Has a land border with China.'],
          sources: [
            {
              name: 'Wikipedia - Countries and territories by number of land borders',
              link: 'https://en.wikipedia.org/wiki/List_of_countries_and_territories_by_number_of_land_borders',
            },
          ],
        },
        {
          description: [
            'Has a land border with Brazil. Does not include overseas departments.',
          ],
          sources: [
            {
              name: 'Wikipedia - Countries and territories by number of land borders',
              link: 'https://en.wikipedia.org/wiki/List_of_countries_and_territories_by_number_of_land_borders',
            },
          ],
        },
        {
          description: [
            'Has a land border with France. Does not include overseas departments.',
          ],
          sources: [
            {
              name: 'Wikipedia - Countries and territories by number of land borders',
              link: 'https://en.wikipedia.org/wiki/List_of_countries_and_territories_by_number_of_land_borders',
            },
          ],
        },
      ],
    },
  ],
};

const section6 = {
  name: 'Facts',
  categories: [
    {
      ids: ['drives_left'],
      name: 'Drives on the left',
      description: [
        'The country follows left-hand traffic rules, meaning vehicles drive on the left side of the road.',
      ],
      sources: [
        {
          name: 'Wikipedia - Left and right-hand traffic',
          link: 'https://en.wikipedia.org/wiki/Left-_and_right-hand_traffic',
        },
      ],
    },
    {
      ids: ['50_plus_skyscrapers'],
      name: 'Has 50+ skyscrapers',
      description: ['Has 50 or more skyscrapers.'],
      sources: [
        {
          name: 'Wikipedia - Countries with the most skyscrapers',
          link: 'https://en.wikipedia.org/wiki/List_of_countries_with_the_most_skyscrapers',
        },
      ],
    },
    {
      ids: ['top_20_obesity_rate'],
      name: 'Top 20 in obesity rate',
      description: [
        'The country ranks among the top 20 in the world for the highest percentage of its population classified as obese, based on body mass index (BMI) data.',
      ],
      sources: [
        {
          name: 'WHO - Prevalence of obesity among adults, BMI >= 30 (crude estimate) (%)',
          link: 'https://www.who.int/data/gho/data/indicators/indicator-details/GHO/prevalence-of-obesity-among-adults-bmi-=-30-(crude-estimate)-(-)',
        },
      ],
    },
    {
      ids: ['alcohol_ban'],
      name: 'Alcohol prohibition',
      description: [
        'The country enforces comprehensive bans on the production, storage, transportation, sale, possession, and consumption of alcoholic beverages.',
      ],
      sources: [
        {
          name: 'Wikipedia - Countries with alcohol prohibition',
          link: 'https://en.wikipedia.org/wiki/List_of_countries_with_alcohol_prohibition',
        },
      ],
    },
    {
      ids: ['top_20_alcohol_consumption'],
      name: 'Top 20 in alcohol consumption',
      description: [
        'The country ranks among the top 20 globally for the highest per capita alcohol consumption, measured in equivalent liters of pure alcohol (ethanol) consumed per year. A standard drink is about 17 millilitres of ethanol, putting one litre at about 59 drinks.',
      ],
      sources: [
        {
          name: 'Wikipedia - Countries by alcohol consumption per capita',
          link: 'https://en.wikipedia.org/wiki/List_of_countries_by_alcohol_consumption_per_capita',
        },
      ],
    },
    {
      ids: ['top_20_chocolate_consumption'],
      name: 'Top 20 in chocolate consumption',
      description: [
        'The country ranks among the top 20 globally for the highest per capita chocolate consumption, measured in kilograms per person per year.',
      ],
      sources: [
        {
          name: 'World Population Review - Chocolate consumption',
          link: 'https://worldpopulationreview.com/country-rankings/which-country-eats-the-most-chocolate',
        },
      ],
    },
    {
      ids: ['top_20_rail_size'],
      name: 'Top 20 countries by rail transport network size',
      description: [
        'Top 20 in rail transport network size based on length of rail lines.',
      ],
      sources: [
        {
          name: 'Wikipedia - Countries by rail transport network size',
          link: 'https://en.wikipedia.org/wiki/List_of_countries_by_rail_transport_network_size',
        },
      ],
    },
    {
      ids: ['top_20_population_density', 'bottom_20_population_density'],
      name: 'Top/Bottom 20 in population density',
      description: [
        'Top or Bottom 20 in population density, expressed as number of people per km².',
      ],
      sources: [
        {
          name: 'Wikipedia - Countries by population density',
          link: 'https://en.wikipedia.org/wiki/List_of_countries_and_dependencies_by_population_density',
        },
      ],
    },
    {
      ids: ['top_20_tourism'],
      name: 'Top 20 in annual tourist arrivals',
      description: [
        'Top 20 in annual tourist arrivals.',
        'The World Tourism rankings are compiled by the United Nations World Tourism Organization as part of their World Tourism Barometer publication, which is released up to six times per year.',
      ],
      sources: [
        {
          name: 'Wikipedia - World Tourism rankings',
          link: 'https://en.wikipedia.org/wiki/World_Tourism_rankings',
        },
      ],
    },
    {
      ids: ['top_20_world_hertitage_sites'],
      name: 'Top 20 in number of World Heritage sites',
      description: [
        'The country is among the top 20 globally for having the most UNESCO World Heritage sites, which are designated for their cultural, historical, or natural significance.',
      ],
      sources: [
        {
          name: 'Wikipedia - World Heritage Sites by country',
          link: 'https://en.wikipedia.org/wiki/World_Heritage_Sites_by_country',
        },
      ],
    },
    {
      ids: ['air_pollution_over_x', 'air_pollution_under_x'],
      name: 'Air pollution μg/m³',
      description: [
        'Over or under a specified concentration of PM2.5 (μg/m³).',
        'It shows the average measured concentration of particulate matter (PM2.5) in micrograms per cubic meter (μg/m³).',
      ],
      sources: [
        {
          name: 'Wikipedia - Countries by air pollution',
          link: 'https://en.wikipedia.org/wiki/List_of_countries_by_air_pollution',
        },
      ],
    },
    {
      ids: [
        'co2_emissions_per_capita_over_x',
        'co2_emissions_per_capita_under_x',
      ],
      name: 'CO₂ emissions per capita tCO₂/year',
      description: [
        'Over or under a specified amount of Fossil tCO₂ emissions per capita.',
        "CO₂ emissions are measured in metric tons of carbon dioxide per year (tCO₂/year) and reflect the amount of carbon dioxide released into the atmosphere from fossil fuel combustion, industrial processes, and other human activities, indicating a country's environmental impact and contribution to global warming.",
      ],
      sources: [
        {
          name: 'Wikipedia - Countries by carbon dioxide emissions',
          link: 'https://en.wikipedia.org/wiki/List_of_countries_by_carbon_dioxide_emissions_per_capita',
        },
      ],
    },
    {
      ids: ['top_10_lakes'],
      name: 'Top 10 in number of lakes',
      description: [
        'Top 10 countries by the overall number of lakes on their territory.',
      ],
      sources: [
        {
          name: 'World Population Review - Number of lakes',
          link: 'https://worldpopulationreview.com/country-rankings/what-country-has-the-most-lakes',
        },
      ],
    },
  ],
};

const section7 = {
  name: 'Sports',
  categories: [
    {
      ids: ['olympic_medals_over_x', 'olympic_medals_under_x'],
      name: 'Olympic medal count',
      description: [
        'Over or under specified Olympic medals across all Olympic games, both Summer and Winter.',
      ],
      sources: [
        {
          name: 'Wikipedia - Olympic medal table',
          link: 'https://en.wikipedia.org/wiki/All-time_Olympic_Games_medal_table',
        },
      ],
    },
    {
      ids: ['no_olympic_medals'],
      name: 'Has never won an Olympic medal',
      description: ['Has never won an Olympic medal.'],
      sources: [
        {
          name: 'Wikipedia - Olympic medal table',
          link: 'https://en.wikipedia.org/wiki/All-time_Olympic_Games_medal_table',
        },
      ],
    },
    {
      ids: ['hosted_olympics'],
      name: 'Has hosted the Olympics',
      description: ['Has hosted the Summer or Winter Olympics.'],
      sources: [
        {
          name: 'Wikipedia - Olympic Games host cities',
          link: 'https://en.wikipedia.org/wiki/List_of_Olympic_Games_host_cities',
        },
      ],
    },
    {
      ids: ['hosted_world_cup'],
      name: "Has hosted the Men's FIFA World Cup",
      description: ["Has hosted the Men's FIFA World Cup."],
      sources: [
        {
          name: 'Wikipedia - FIFA World Cup hosts',
          link: 'https://en.wikipedia.org/wiki/FIFA_World_Cup_hosts',
        },
      ],
    },
    {
      ids: ['played_world_cup'],
      name: "Has played in the Men's FIFA World Cup",
      description: ["Has played in the Men's FIFA World Cup."],
      sources: [
        {
          name: 'Wikipedia - National team appearances in the FIFA World Cup',
          link: 'https://en.wikipedia.org/wiki/National_team_appearances_in_the_FIFA_World_Cup',
        },
      ],
    },
    {
      ids: ['won_world_cup'],
      name: "Has won the Men's FIFA World Cup",
      description: ["Has won the Men's FIFA World Cup."],
      sources: [
        {
          name: 'Wikipedia - List of FIFA World Cup finals',
          link: 'https://en.wikipedia.org/wiki/List_of_FIFA_World_Cup_finals#Results',
        },
      ],
    },
    {
      ids: ['hosted_f1'],
      name: 'Has hosted a Formula 1 Grand Prix',
      description: ['Has hosted a Formula 1 Grand Prix'],
      sources: [
        {
          name: 'Wikipedia - Formula One Grands Prix by host nation',
          link: 'https://en.wikipedia.org/wiki/List_of_Formula_One_Grands_Prix#By_host_nation',
        },
      ],
    },
  ],
};

const section8 = {
  name: 'Political',
  categories: [
    {
      ids: ['in_eu'],
      name: 'Member of the European Union',
      description: [
        'The country is part of the European Union (EU), a political and economic union of member states located primarily in Europe that share common policies and regulations.',
      ],
      sources: [
        {
          name: 'Wikipedia - Member state of the European Union',
          link: 'https://en.wikipedia.org/wiki/Member_state_of_the_European_Union',
        },
      ],
    },
    {
      ids: ['commonwealth_member'],
      name: 'Member of the Commonwealth',
      description: [
        'The country is part of the Commonwealth of Nations, a political association of member states, most of which were former territories of the British Empire, that cooperate on shared goals and values.',
      ],
      sources: [
        {
          name: 'Wikipedia - Member states of the Commonwealth',
          link: 'https://en.wikipedia.org/wiki/Member_states_of_the_Commonwealth_of_Nations',
        },
      ],
    },
    {
      ids: ['was_ussr'],
      name: 'Was part of the USSR',
      description: [
        'The country was formerly a part of the Union of Soviet Socialist Republics (USSR), a federal socialist state that existed from 1922 to 1991.',
      ],
      sources: [
        {
          name: 'Wikipedia - Former Soviet Union countries',
          link: 'https://en.wikipedia.org/wiki/Post-Soviet_states',
        },
      ],
    },
    {
      ids: ['is_monarchy'],
      name: 'Is a monarchy',
      description: [
        'The country is governed by a monarchy, where the head of state is a king, queen, or emperor, either ruling with full or limited constitutional powers.',
      ],
      sources: [
        {
          name: 'Wikipedia - Monarchy',
          link: 'https://en.wikipedia.org/wiki/Monarchy#Current_monarchies',
        },
      ],
    },
    {
      ids: ['is_territory'],
      name: 'Is a dependency/territory',
      description: [
        'The country or region is not fully sovereign and is governed or overseen by another country, often enjoying limited autonomy.',
      ],
      sources: [
        {
          name: 'Wikipedia - Dependent territory',
          link: 'https://en.wikipedia.org/wiki/Dependent_territory',
        },
      ],
    },
    {
      ids: ['has_nuclear_weapons'],
      name: 'Has nuclear weapons',
      description: [
        'The country possesses and maintains nuclear weapons as part of its military capabilities.',
      ],
      sources: [
        {
          name: 'Wikipedia - States with nuclear weapons',
          link: 'https://en.wikipedia.org/wiki/List_of_states_with_nuclear_weapons',
        },
      ],
    },
    {
      ids: ['official_language'],
      name: 'X is an official language',
      subCategories: [
        {
          description: ['Spanish is an official language.'],
          sources: [
            {
              name: 'Wikipedia - Countries where Spanish is an official language',
              link: 'https://en.wikipedia.org/wiki/List_of_countries_where_Spanish_is_an_official_language',
            },
          ],
        },
        {
          description: ['Portuguese is an official language.'],
          sources: [
            {
              name: 'Wikipedia - Countries where Portuguese is an official language',
              link: 'https://en.wikipedia.org/wiki/List_of_countries_and_territories_where_Portuguese_is_an_official_language',
            },
          ],
        },
        {
          description: ['French is an official language.'],
          sources: [
            {
              name: 'Wikipedia - Countries where French is an official language',
              link: 'https://en.wikipedia.org/wiki/List_of_countries_and_territories_where_French_is_an_official_language',
            },
          ],
        },
        {
          description: ['Arabic is an official language.'],
          sources: [
            {
              name: 'Wikipedia - Countries where Arabic is an official language',
              link: 'https://en.wikipedia.org/wiki/List_of_countries_and_territories_where_Arabic_is_an_official_language',
            },
          ],
        },
        {
          description: ['English is an official language.'],
          sources: [
            {
              name: 'Wikipedia - Countries where English is an official language',
              link: 'https://en.wikipedia.org/wiki/List_of_countries_and_territories_where_English_is_an_official_language',
            },
          ],
        },
      ],
    },
    {
      ids: ['same_sex_marriage_legal'],
      name: 'Same-sex marriage legalised',
      description: ['Same-sex marriage is legally performed and recognized.'],
      sources: [
        {
          name: 'Wikipedia - Legal status of same-sex marriage',
          link: 'https://en.wikipedia.org/wiki/Same-sex_marriage#Same-sex_marriage_around_the_world',
        },
      ],
    },
    {
      ids: ['same_sex_activities_illegal'],
      name: 'Same-sex activities are illegal',
      description: [
        'Same-sex activities are classified as a criminal offense.',
      ],
      sources: [
        {
          name: 'Wikipedia - Criminalization of homosexuality',
          link: 'https://en.wikipedia.org/wiki/Criminalization_of_homosexuality#Current_status',
        },
      ],
    },
    {
      ids: ['cpi_over_x', 'cpi_under_x'],
      name: 'Corruption Perceptions Index',
      description: [
        'Over or under specified CPI.',
        'Corruption Perceptions Index (CPI) is an index that scores and ranks countries by their perceived levels of public sector corruption. The 2023 CPI, published in January 2024, currently ranks 180 countries "on a scale from 100 (very clean) to 0 (highly corrupt)" based on the situation between 1 May 2022 and 30 April 2023.',
      ],
      sources: [
        {
          name: 'Wikipedia - Corruption Perceptions Index',
          link: 'https://en.wikipedia.org/wiki/Corruption_Perceptions_Index#2020%E2%80%932023',
        },
      ],
    },
    {
      ids: ['multiple_time_zones'],
      name: 'Has more than 1 time zone',
      description: [
        'The country spans across multiple time zones due to its large geographical area or territories, requiring different regions to operate on different local times.',
      ],
      sources: [
        {
          name: 'Wikipedia - Time zones by country',
          link: 'https://en.wikipedia.org/wiki/List_of_time_zones_by_country',
        },
      ],
    },
    {
      ids: ['observes_x_time_zone'],
      name: 'Observes X time zone',
      description: [
        'Observe a specific UTC time zone when DST is not in effect.',
      ],
      sources: [
        {
          name: 'Wikipedia - Time zones',
          link: 'https://en.wikipedia.org/wiki/Time_zone',
        },
      ],
    },
    {
      ids: ['observes_dst'],
      name: 'Observes Daylight Saving Time',
      description: [
        'The country adjusts its clocks forward in the spring and back in the fall to make better use of daylight during the longer days of summer.',
      ],
      sources: [
        {
          name: 'Wikipedia - Daylight Saving Time',
          link: 'https://en.wikipedia.org/wiki/Daylight_saving_time',
        },
      ],
    },
  ],
};

const section9 = {
  name: 'Population',
  categories: [
    {
      ids: ['population_over_x', 'population_under_x'],
      name: 'Population count',
      description: [
        'Over or under specified population.',
        "The data for each country's population is taken from the information box of that country's Wikipedia page. The data is retrieved from the Wikipedia pages every 1st of each month.",
      ],
      stats: [
        '25th percentile: 2,000,000',
        '50th percentile: 8,000,000',
        '75th percentile: 30,000,000',
        'Average: 40,000,000',
      ],
      sources: [
        {
          name: 'Wikipedia',
          link: 'https://wikipedia.org/',
        },
      ],
    },
    {
      ids: ['capital_population_over_x', 'captial_population_under_x'],
      name: 'Capital city population',
      description: [
        'Over or under specified population of capital city. Only the official capital area is considered.',
        "The data for each city's population is taken from the information box of that city's Wikipedia page. The data is retrieved from the Wikipedia pages every 1st of each month.",
      ],
      stats: [
        '25th percentile: 282,000',
        '50th percentile: 1,134,000',
        '75th percentile: 2,801,000',
        'Average: 2,765,000',
      ],
      sources: [
        {
          name: 'Wikipedia',
          link: 'https://wikipedia.org/',
        },
      ],
    },
    {
      ids: ['capital_not_most_populated_city'],
      name: 'Capital is not the most populated city',
      description: [
        'The capital city of the country is not the largest in terms of population, with another city having a higher population.',
        "For example, Australia's capital, Canberra, is not the most populated city, as Sydney holds that distinction.",
      ],
      sources: [],
    },
  ],
};

const section10 = {
  name: 'Size',
  categories: [
    {
      ids: ['size_over_x', 'size_under_x'],
      name: 'Area km²',
      description: [
        'Over or under specified area in km². Excludes overseas departments.',
      ],
      stats: [
        '25th percentile: 22,000 km²',
        '50th percentile: 115,000 km²',
        '75th percentile: 524,000 km²',
        'Average: 675,000 km²',
      ],
      sources: [
        {
          name: 'Wikipedia - List of countries and dependencies by area',
          link: 'https://en.wikipedia.org/wiki/List_of_countries_and_dependencies_by_area',
        },
      ],
    },
  ],
};

const categories = [
  section1,
  section2,
  section3,
  section4,
  section5,
  section6,
  section7,
  section8,
  section9,
  section10,
];

export default categories;
