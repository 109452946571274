<template>
  <div class="modal-bg" @click.self="$emit('close')">
    <div class="content">
      <div class="close-btn" @click="$emit('close')">
        <div />
        <div />
      </div>
      <div v-if="userDetails" class="flex-container">
        <div class="header">
          <div class="header-title">Account</div>
        </div>
        <div class="left">
          Hi {{ userDetails.firstName }} 👋
          <br />
          <br />
          We now store your stats after each game. This not only safeguards your
          progress but also enables seamless gameplay across multiple devices.
          <a
            class="link"
            href="https://account.teuteuf.fr"
            target="_blank"
            >Learn more.</a
          >
          <br />
          <br />
          <div class="button-list" style="margin-top: 10px">
            <button class="btn" @click="logout">Sign out</button>
            &nbsp;&nbsp;
            <a
              class="btn"
              href="https://account.teuteuf.fr"
              target="_blank"
              >Manage account</a
            >
          </div>
        </div>
      </div>
      <div v-else class="flex-container">
        <div class="header">
          <div class="header-title">Account</div>
        </div>
        <div class="inner-content">
          <div class="subheading">
            Sign up for a Teuteuf Games account or log in to start saving your
            gaming statistics and playing across multiple devices. Want to know
            all the benefits?
            <a
              class="link"
              href="https://account.teuteuf.fr"
              target="_blank"
              >Discover more here.</a
            >
          </div>
          <div class="social-link-list">
            <a
              class="social-link"
              :href="`${authServerUrl}/auth/twitter?referer=${referrer}`"
            >
              <div class="icon"><img src="/images/X.svg" /></div>
              <div class="text">Login with X</div>
            </a>
            <a
              class="social-link"
              :href="`${authServerUrl}/auth/google?referer=${referrer}`"
              style="background-color: #4285f4"
            >
              <div class="icon"><img src="/images/google.png" /></div>
              <div class="text">Login with Google</div>
            </a>
            <a
              class="social-link"
              :href="`${authServerUrl}/auth/apple?referer=${referrer}`"
            >
              <div class="icon"><img src="/images/apple.png" /></div>
              <div class="text">Login with Apple</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
export default {
  name: "AccountPopup",
  setup() {
    return {
      userDetails: inject("userDetails"),
    };
  },
  data() {
    return {
      authServerUrl: "",
      referrer: "",
    };
  },
  mounted() {
    const isLocalhost = window.location.origin.includes("localhost");
    const authServerUrl = isLocalhost
      ? "http://localhost:3000"
      : "https://auth.teuteuf.fr";
    const referrer = isLocalhost ? "localhost-8080" : "geogrid";
    this.authServerUrl = authServerUrl;
    this.referrer = referrer;
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("refreshTime");
      this.userDetails = null;
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.content {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #353548;
  padding: 32px;
  border-radius: 16px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  text-align: center;
  width: 60%;
  max-width: 800px;
  color: white;
  overflow: hidden;
}

.flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex-grow: 1;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: center;
  width: 100%;
}

.header-title {
  font-size: 1.75rem;
  font-weight: 600;
}

.inner-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 20px;
  overflow-y: auto;

  /* hide scrollbar */
  ::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
  -ms-overflow-style: none;
}

.subheading {
  font-size: 1rem;
  text-align: center;
}

.left {
  text-align: left;
}

.social-link-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.close-btn {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 32px;
  right: 32px;
  cursor: pointer;
}

.close-btn > * {
  background: white;
  width: 18px;
  height: 2px;
  position: absolute;
  top: calc(50% - 1px);
  left: calc(50% - 10px);
}

.close-btn > *:first-child {
  transform: rotateZ(45deg);
}

.close-btn > *:last-child {
  transform: rotateZ(-45deg);
}

button.btn,
a.btn {
  font-size: small;
  text-decoration: none;
}

a.link {
  color: #00a0e4;
  text-decoration-style: dotted;
}

a.social-link {
  display: flex;
  background-color: black;
  color: white;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 0.375rem;
  padding: 0.25rem;
  width: 13rem;
  text-decoration: none;
}

a.social-link > div.icon {
  width: 2.5rem;
  height: 2.5rem;
  background-color: white;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.375rem;
}

a.social-link > div.icon > img {
  width: 100%;
  height: 100%;
}

a.social-link > div.text {
  flex: 1 1;
  text-align: center;
  font-size: 0.875rem;
  font-weight: bold;
}

@media screen and (max-width: 1028px) {
  .content {
    width: 70%;
  }
}

@media screen and (max-width: 768px) {
  .content {
    width: 85%;
    padding: 20px;
  }

  .header-title {
    font-size: 1.1rem;
    margin-bottom: 10px;
  }

  .subheading {
    font-size: 0.75rem;
    margin-bottom: 10px;
  }

  .close-btn {
    top: 18px;
    right: 18px;
  }
}
</style>
