<template>
  <div class="wrapper">
    <div id="adngin-sb_right-0"></div>
  </div>
</template>

<script>
import { activateAdUnit } from "../../utils/ads";
export default {
  name: "SbRightAd",
  inject: ["isAdScriptLoaded"],
  watch: {
    isAdScriptLoaded: {
      handler(isLoaded) {
        if (!isLoaded) return;
        if (window.innerWidth < 1130) return;
        activateAdUnit("sb_right");
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.wrapper {
  width: 300px;
  height: 600px;
  position: absolute;
  z-index: 10;
  padding: 6rem 0.8rem;
  top: 0;
  right: 0;
}
@media (max-width: 1130px) {
  .wrapper {
    display: none;
  }
}
</style>
