<!-- ADD META TAGS FOR SEARCH BOOSTING -->
<template>
  <div class="container">
    <nav class="top-nav">
      <a class="logo" href="https://teuteuf.fr" target="_blank" rel="noopener noreferrer"><img src="/teuteuf.svg"
          alt="teuteuf logo" /></a>
      <div class="top-nav-inner">
        <button @click="showTeuteufGamesPopup" class="nav-btn">
          <div class="tooltip">Teuteuf Games</div>
          <span style="font-size: 20px; display: flex"><svg xmlns="http://www.w3.org/2000/svg" width="28" height="24"
              viewBox="0 0 512 512" fill="#7550cc">
              <g>
                <path d="M447.303,128.681c-0.427-1.259-0.875-3.264-1.408-5.525c-3.52-14.315-6.827-27.84-19.904-32.832l-62.08-23.616
                  c-12.032-4.565-25.515-2.923-36.139,4.48l-3.84,3.435c-3.115,3.563-7.339,8.021-10.347,10.88H199.197
                  c-2.539-2.667-5.931-6.592-8.768-10.112l-4.437-4.16c-10.624-7.424-24.149-9.131-36.117-4.523L87.73,90.324
                  c-13.013,4.949-16.277,18.325-19.755,32.512c-0.533,2.155-0.981,4.117-0.811,4.117c0,0,0.021,0,0.043-0.021
                  C46.045,168.66-54.606,379.902,39.623,442.26c13.483,8.917,31.424,7.509,43.691-3.456l73.856-66.219
                  c6.507-5.845,14.784-9.067,23.275-9.067h152.853c8.491,0,16.747,3.221,23.275,9.067l73.856,66.219
                  c6.955,6.229,15.616,9.408,24.277,9.408c6.955,0,13.888-2.069,19.883-6.293C557.746,383.572,482.823,202.494,447.303,128.681z
                    M341.469,149.502c11.776,0,21.333,9.557,21.333,21.333c0,11.776-9.557,21.333-21.333,21.333s-21.333-9.557-21.333-21.333
                  S329.693,149.502,341.469,149.502z M213.469,256.169h-21.333v21.333c0,11.776-9.536,21.333-21.333,21.333
                  s-21.333-9.557-21.333-21.333v-21.333h-21.333c-11.797,0-21.333-9.557-21.333-21.333s9.536-21.333,21.333-21.333h21.333V192.17
                  c0-11.776,9.536-21.333,21.333-21.333s21.333,9.557,21.333,21.333v21.333h21.333c11.797,0,21.333,9.557,21.333,21.333
                  S225.266,256.169,213.469,256.169z M298.802,234.836c-11.776,0-21.333-9.557-21.333-21.333s9.557-21.333,21.333-21.333
                  s21.333,9.557,21.333,21.333S310.578,234.836,298.802,234.836z M341.469,277.502c-11.776,0-21.333-9.557-21.333-21.333
                  s9.557-21.333,21.333-21.333s21.333,9.557,21.333,21.333S353.245,277.502,341.469,277.502z M384.135,234.836
                  c-11.776,0-21.333-9.557-21.333-21.333s9.557-21.333,21.333-21.333s21.333,9.557,21.333,21.333S395.911,234.836,384.135,234.836z"
                  />
              </g>
            </svg></span>
        </button>
        <button @click="showCategoryInfoPopup" class="nav-btn">
          <div class="tooltip">Categories Info</div>

          <span style="font-size: 20px; display: flex"><svg xmlns="http://www.w3.org/2000/svg" width="28" height="24"
              viewBox="0 0 512 512">
              <path fill="#0cc46e"
                d="M40 48C26.7 48 16 58.7 16 72l0 48c0 13.3 10.7 24 24 24l48 0c13.3 0 24-10.7 24-24l0-48c0-13.3-10.7-24-24-24L40 48zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L192 64zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-288 0zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-288 0zM16 232l0 48c0 13.3 10.7 24 24 24l48 0c13.3 0 24-10.7 24-24l0-48c0-13.3-10.7-24-24-24l-48 0c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24l0 48c0 13.3 10.7 24 24 24l48 0c13.3 0 24-10.7 24-24l0-48c0-13.3-10.7-24-24-24l-48 0z" />
            </svg></span>
        </button>
        <button onclick="window.open('https://teuteuf.notion.site/17eeff4e9f3880e2a6aeef075e74939b?pvs=105', '_blank')" class="nav-btn">
          <div class="tooltip">Submit Feedback</div>

          <span style="font-size: 20px; display: flex"><svg width="28" height="24" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512">
              <path fill="#ccc" stroke="#000" stroke-width="10"
                d="M512 240c0 114.9-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6C73.6 471.1 44.7 480 16 480c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c0 0 0 0 0 0s0 0 0 0s0 0 0 0c0 0 0 0 0 0l.3-.3c.3-.3 .7-.7 1.3-1.4c1.1-1.2 2.8-3.1 4.9-5.7c4.1-5 9.6-12.4 15.2-21.6c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208z" />
            </svg>
          </span>
        </button>
        <button @click="showGameInfoPopup" class="nav-btn">
          <div class="tooltip">Game Info</div>

          <span style="font-size: 20px; display: flex"><svg width="28" height="24" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512">
              <path fill="#f2314e"
                d="M80 160c0-35.3 28.7-64 64-64l32 0c35.3 0 64 28.7 64 64l0 3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74l0 1.4c0 17.7 14.3 32 32 32s32-14.3 32-32l0-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7l0-3.6c0-70.7-57.3-128-128-128l-32 0C73.3 32 16 89.3 16 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" />
            </svg></span>
        </button>
        <button v-if="userDetails?.photoURL" @click="showAccountPopup" class="nav-btn avatar" :style="{ backgroundImage: 'url(' + userDetails?.photoURL + ')' }">
          <div class="tooltip">Account</div>
        </button>
        <button v-else @click="showAccountPopup" class="nav-btn">
          <div class="tooltip">Account</div>

          <span style="font-size: 20px; display: flex">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path
                fill="#779ecb"
                d="M19 7.001c0 3.865-3.134 7-7 7s-7-3.135-7-7c0-3.867 3.134-7.001 7-7.001s7 3.134 7 7.001zm-1.598 7.18c-1.506 1.137-3.374 1.82-5.402 1.82-2.03 0-3.899-.685-5.407-1.822-4.072 1.793-6.593 7.376-6.593 9.821h24c0-2.423-2.6-8.006-6.598-9.819z"/>
            </svg>
          </span>
        </button>
      </div>
    </nav>
    <IFrameWarningToast />
    <ContentUpdateToast />
    <DateChecker :boardId="boardId" />
    <h1 class="page-title">GeoGrid 🌎 Board #{{ boardId }} </h1>
    <game-info v-if="isGameInfoPopup" @close="hideGameInfoPopup" />
    <categoryInfo v-if="isCategoryInfoPopup" :category-id="selectedCategoryId" @close="hideCategoryInfoPopup" />
    <TeuteufGames v-if="isTeuteufGamesPopup" @close="hideTeuteufGamesPopup" />
    <AccountPopup v-if="isAccountPopup" @close="hideAccountPopup" />
    <NoDataToast v-if="isNoDataPopup" :category="noDataPopupCategory" :country-name="noDataPopupCountry" @close="hideNoDataPopup" />

    <button @click="showPrevBoardPopup" class="prev-grids-btn btn">Play Previous Grids!</button>
    <prev-board-popup v-if="isPrevBoardPopup" @select-board="hidePrevBoardPopup" @close="hidePrevBoardPopup" />
    <!-- sidebar ad left -->
    <sb-left-ad></sb-left-ad>
    <loading-spinner v-if="loading" />
    <div v-else>
      <div :key="gameData" v-if="gameData && countryData">
        <div class="board">
          <div class="row">
            <div class="filler" />
            <div v-for="(column, index) in gameData.columns" :key="'col-' + index" class="prompt column-prompt"
              @click="openCategory(column.id)">
              {{ column.name }}
            </div>
          </div>
          <div v-for="(row, rowIndex) in gameData.rows" :key="'row-' + rowIndex" class="row">
            <div class="prompt row-prompt" @click="openCategory(row.id)">
              {{ row.name }}
            </div>
            <div v-for="(column, colIndex) in gameData.columns" :key="'col-' + colIndex" class="box"
              @click="!isGameOver && !board[rowIndex][colIndex] && showSearch(rowIndex, colIndex)" :class="{
                'highlighted': searching && selectedRowIndex === rowIndex && selectedColIndex === colIndex,
                'wrong animate-shake': wrongGuessLocation.length === 2 && wrongGuessLocation[0] === rowIndex && wrongGuessLocation[1] === colIndex,
                'guessed': isGameOver || board[rowIndex][colIndex],
              }">
              <div class="rarity-element" v-if="board[rowIndex][colIndex] !== ''"
                :style="getRarityStyle(rowIndex, colIndex)">{{ getRarityText(rowIndex, colIndex) }}</div>
              <div v-if="board[rowIndex][colIndex] !== ''" class="flag-container">
                <img
                  v-if="guessedCountries.includes(board[rowIndex][colIndex]) && getImageUrl(board[rowIndex][colIndex]) !== ''"
                  :src="getImageUrl(board[rowIndex][colIndex])" alt="Country flag" class="country-flag" />
              </div>
              <template v-if="board[rowIndex][colIndex] !== ''">
                <div class="country-info">{{ board[rowIndex][colIndex] }}</div>
              </template>
            </div>
          </div>
        </div>
        <!-- mobile mrec ad -->
        <mobile-mrec-ad></mobile-mrec-ad>
        <div class="guesses-and-score">
          <h2>Guesses left: {{ infiniteMode ? '∞' : guesses }} &nbsp; &nbsp; Score: {{ score }}</h2>
        </div>
      </div>
      <p v-else style="color: white;">No data available</p>
    </div>
    <button v-if="!isGameOver" @click="toggleInfiniteMode" :class="{ selected: infiniteMode }" class="infinity-btn btn"
      :disabled="isGameOver">
      {{ infiniteMode ? 'Disable Infinite Mode' : 'Enable Infinite Mode' }}
    </button>
    <button v-if="!isGameOver" class="restart-btn btn" @click="restartOrGiveUp()"
      :disabled="!anyGuessPlaced() || isGameOver">
      {{ isGameOver ? 'Game Over' : 'Give Up' }}
    </button>
    <button v-if="isGameOver" @click="showGameOver" class="show-summary-btn btn">Show Summary</button>
    <p class="category-help-msg">Click the <span><svg style="position: relative; top: 5px;"
          xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 512 512">
          <path fill="#0cc46e"
            d="M40 48C26.7 48 16 58.7 16 72l0 48c0 13.3 10.7 24 24 24l48 0c13.3 0 24-10.7 24-24l0-48c0-13.3-10.7-24-24-24L40 48zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L192 64zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-288 0zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-288 0zM16 232l0 48c0 13.3 10.7 24 24 24l48 0c13.3 0 24-10.7 24-24l0-48c0-13.3-10.7-24-24-24l-48 0c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24l0 48c0 13.3 10.7 24 24 24l48 0c13.3 0 24-10.7 24-24l0-48c0-13.3-10.7-24-24-24l-48 0z" />
        </svg></span> icon for help with a category</p>
    <social-links></social-links>
    <!-- sidebar ad right -->
    <sb-right-ad></sb-right-ad>
    <!-- Popups -->
     <!-- Do not allow Antarctica to be guessed -->
    <search-popup v-if="showSearchPopup && countryData"
      :guessed-countries="board.flat()"
      :countries="countryData.filter((c) => c.code.toLowerCase() !== 'aq').map(c => { return { code: c.code, name: c.name } })"
      @close="hideSearch"
      @guess="handleGuess"
    />
    <game-over v-if="showGameOverPopup" :score="score" :boardId="boardId" :board="board"
      :liveRarityData="liveRarityData" :gameData="gameData" :countryData="countryData" :answersList="answersList"
      :wrongGuesses="wrongGuesses"
      @close="hideGameOver" />
    <!-- mobile adhesive ad right -->
    <mobile-adhesive-ad></mobile-adhesive-ad>
  </div>
</template>

<script>
import { inject } from "vue";
import axios from 'axios';
import dayjs from 'dayjs';
import SearchPopup from './searchPopup.vue';
import GameOver from './gameSummary/gameOver.vue';
import GameInfo from './gameInfo.vue';
import PrevBoardPopup from './prevBoardPopup.vue';
import config from '../axiospath.js';
import categoryInfo from './categoryAtlas/categoryAtlas.vue';
import SbLeftAd from './ads/sbLeftAd.vue';
import SbRightAd from './ads/sbRightAd.vue';
import MobileMrecAd from './ads/mobileMrecAd.vue';
import LoadingSpinner from './loadingSpinner.vue';
import ContentUpdateToast from './toasts/contentUpdateToast.vue';
import DateChecker from './dateChecker.vue';
import getBoardId from '@/utils/getBoardId';
import getImageUrl from '@/utils/getImageUrl';
import TeuteufGames from './TeuteufGames.vue';
import { getBoard, getCountries, getCountryGeoGridData } from '@/utils/fetchDataFromCDN'
import IFrameWarningToast from './toasts/IFrameWarningToast.vue';
import SocialLinks from './socialLinks.vue';
import NoDataToast from './toasts/noDataToast.vue';
import { NO_DATA_CATEGORIES, checkGeoGridDataForNoData } from '@/utils/noDataCategoriesUtils'
import AccountPopup from './AccountPopup.vue';
import { setData } from "@/utils/server";
import MobileAdhesiveAd from "./ads/mobileAdhesiveAd.vue";

export default {
  components: {
    SearchPopup,
    GameOver,
    PrevBoardPopup,
    GameInfo,
    categoryInfo,
    SbLeftAd,
    SbRightAd,
    MobileMrecAd,
    MobileAdhesiveAd,
    LoadingSpinner,
    ContentUpdateToast,
    DateChecker,
    TeuteufGames,
    AccountPopup,
    IFrameWarningToast,
    SocialLinks,
    NoDataToast,
  },
  setup() {
    const userDetails = inject('userDetails');
    return {
      userDetails,
    };
  },
  data() {
    return {
      gameData: null,
      countryData: null,
      liveRarityData: null,
      board: [
        ['', '', ''],
        ['', '', ''],
        ['', '', '']
      ],
      rarityPercentages: [],
      guesses: 10,
      guessedCountries: [],
      wrongGuesses: [],
      showSearchPopup: false,
      selectedRowIndex: -1,
      selectedColIndex: -1,
      isGameOver: false,
      infiniteMode: false,
      searching: false,
      boardId: '',
      isPrevBoardPopup: false,
      isGameInfoPopup: false,
      isCategoryInfoPopup: false,
      isAccountPopup: false,
      showGameOverPopup: false,
      isGuessing: false,
      imageUrlCache: {},
      score: 900,
      wrongGuessLocation: [],
      loading: true,
      selectedCategoryId: null,
      isTeuteufGamesPopup: false,
      isNoDataPopup: false,
      noDataPopupCountry: null,
      noDataPopupCategory: null,
    };
  },
  async mounted() {
    // Check if the user has visited the site before
    const hasVisitedBefore = localStorage.getItem('hasVisitedBefore');
    if (hasVisitedBefore) {
      this.hasVisitedBefore = true;
    } else {
      this.isGameInfoPopup = true;
      this.hasVisitedBefore = true;
      localStorage.setItem('hasVisitedBefore', true);
    }

    // Get the board ID from the URL
    const boardIdFromUrl = window.location.pathname.split('/board/')[1];
    // Get todays board ID from todays date
    const todaysBoardId = getBoardId(dayjs().format('YYYY-MM-DD'));

    if (boardIdFromUrl) {
      this.boardId = boardIdFromUrl;

      // don't show future board data
      if (Number(boardIdFromUrl) > todaysBoardId) {
        this.loading = false;
        return;
      }

      this.loadDataFromLocalStorage();
    } else {
      try {
        this.boardId = todaysBoardId.toString();
        this.loadDataFromLocalStorage();
      } catch (error) {
        console.error('Error fetching boards:', error);
      }
    }

    // Tooltips
    document.querySelectorAll(".nav-btn").forEach((button) => {
      const tooltip = button.querySelector(".tooltip");
      const rect = button.getBoundingClientRect();
      tooltip.style.left = `${rect.width / 2}px`;
      tooltip.style.top = `${rect.height + 10}px`;

      // Check if the tooltip goes out of bounds on the right
      const tooltipRect = tooltip.getBoundingClientRect();
      if (tooltipRect.right > window.innerWidth) {
        tooltip.style.left = `${-(tooltipRect.right - window.innerWidth)}px`;
      }
    });

    // Fetch game data, answers, country data, and live rarity data
    await Promise.all([
      this.fetchGameData(),
      this.getCountryData(),
      this.getLiveRarity()
    ]);

    this.loading = false;
  },

  watch: {
    // Load data from local storage when boardId changes
    boardId(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.loadDataFromLocalStorage();
      }
    },

    // Fetch game data when boardId changes
    async '$route.params.board_id'(newValue) {
      if (newValue !== this.boardId) {
        if (Number(newValue) > getBoardId(dayjs().format('YYYY-MM-DD'))) {
          // don't show future board data
          this.boardId = newValue;
          return;
        }
        this.loading = true;
        this.boardId = newValue;
        this.infiniteMode = false;
        await Promise.all([
          this.fetchGameData(),
          this.getLiveRarity()
        ]);
        this.loading = false;
      }
    }
  },
  methods: {

    // Fetch board from the CDN
    async fetchGameData() {
      this.gameData = null;
      this.answersList = null;
      try {
        this.gameData = await getBoard(this.boardId);
        this.answersList = this.gameData.answers;
      } catch (error) {
        console.error('Error fetching game data:', error);
      }
    },

    // Show the search popup for a specific box
    showSearch(rowIndex, colIndex) {
      this.selectedRowIndex = rowIndex;
      this.selectedColIndex = colIndex;
      this.showSearchPopup = true;
      this.searching = true;
    },

    // Hide the search popup
    hideSearch() {
      this.showSearchPopup = false;
      this.searching = false;
    },

    // Restart the game, reset all data
    restartGame() {
      this.deleteUserDataForCurrentBoard();

      this.isGameOver = false;
      this.guesses = 10;
      this.score = 900;
      this.guessedCountries = [];
      this.infiniteMode = false;
      this.board = [
        ['', '', ''],
        ['', '', ''],
        ['', '', '']
      ];
      this.rarityPercentages = [];
      this.initializeRarityPercentages();
      this.deleteUserDataForCurrentBoard();
    },

    // Show the Teuteuf Games popup
    showTeuteufGamesPopup() {
      this.isTeuteufGamesPopup = true;
    },

    // Hide the Teuteuf Games popup
    hideTeuteufGamesPopup() {
      this.isTeuteufGamesPopup = false;
    },

    // Show the game over popup
    showGameOver() {
      this.showGameOverPopup = true;
    },

    // Hide the game over popup
    hideGameOver() {
      this.showGameOverPopup = false;
    },

    // Show the previous board popup
    showPrevBoardPopup() {
      this.isPrevBoardPopup = true;
    },

    // Hide the previous board popup
    hidePrevBoardPopup() {
      this.isPrevBoardPopup = false;
    },

    // Show the game info popup
    showGameInfoPopup() {
      this.isGameInfoPopup = true;
    },

    // Hide the game info popup
    hideGameInfoPopup() {
      this.isGameInfoPopup = false;
    },

    // Show the category info popup
    showCategoryInfoPopup() {
      this.isCategoryInfoPopup = true;
    },

    // Hide the category info popup
    hideCategoryInfoPopup() {
      this.isCategoryInfoPopup = false;
      this.selectedCategoryId = null;
    },

    openCategory(categoryId) {
      this.selectedCategoryId = categoryId;
      this.showCategoryInfoPopup();
    },

    // Show the account popup
    showAccountPopup() {
      this.isAccountPopup = true;
    },

    // Hide the account popup
    hideAccountPopup() {
      this.isAccountPopup = false;
    },

    // Show the no data popup
    showNoDataPopup(categoryName, countryName) {
      this.isNoDataPopup = true;
      this.noDataPopupCountry = countryName;
      this.noDataPopupCategory = categoryName;
    },

    // Hide the no data popup
    hideNoDataPopup() {
      this.isNoDataPopup = false;
      this.noDataPopupCountry = null;
      this.noDataPopupCategory = null;
    },

    // Fetch country list from CDN
    async getCountryData() {
      try {
        this.countryData = await getCountries();
      } catch (error) {
        console.error('Error fetching country data:', error);
      }
    },

    // Fetch country geogrid data from CDN
    async getGeogridData(countryCode) {
      try {
        return await getCountryGeoGridData(countryCode);
      } catch (error) {
        console.error('Error fetching geogrid data:', error);
        return null;
      }
    },

    // Fetch live rarity data from the API
    async getLiveRarity() {
      try {
        const liveRarityDataResponse = await axios.get(`${config.baseURL}/game/rarity/` + this.boardId);
        this.liveRarityData = liveRarityDataResponse.data;
      } catch (error) {
        console.error('Error fetching country data:', error);
      }
    },

    // Toggle infinite mode
    toggleInfiniteMode() {
      if (!this.infiniteMode) {
        this.previousGuesses = this.guesses;
      } else {
        this.guesses = this.previousGuesses;
      }
      this.infiniteMode = !this.infiniteMode;
    },

    /**
     * Check if we have no data for a country for a particular category
     * @param categoryId the category to check
     * @param countryCode the country to check
     *
     * @returns boolean - true if the country does not have any data for the category, false otherwise
     */
    async checkForNullCategoryData(categoryId, countryCode) {
      if (!NO_DATA_CATEGORIES.includes(categoryId)) {
        // this is not a no data category, no need to check the country data
        return false;
      }

      try {
        const data = await this.getGeogridData(countryCode);
        if (!data) return false;
        return checkGeoGridDataForNoData(categoryId, data);
      } catch (e) {
        console.error('checkForNullCategoryData: ', e);
        return false;
      }
    },

    // Handle the guess made by the user
    async handleGuess({ code, name: guess }) {
      if (this.isGuessing) {
        return;
      }

    this.isGuessing = true;
    //increment play count when first guess placed
    if (this.guessedCountries.length === 0) {
      const url = `${config.baseURL}/game/stats/${this.boardId}?score=${this.score}&flag=1`;
      try {
        axios.post(url);
      } catch (error) {
        console.error('Error making request:', error);
      }
    }
    // Check if the guess has already been used
    if (this.checkIfCountryHasBeenUsed(guess)) {
      return;
    }

      // Make the guess
      try {
        const index =
          this.selectedRowIndex * this.gameData.columns.length +
          this.selectedColIndex +
          1;

        const correct = this.answersList['match_box_' + index].includes(guess);

        // Before counting the guess, check if this is a no data category
        // Don't count the guess if we have no data for the category
        // Also save wrong guess data
        if (!correct) {
          const colCategory = this.gameData.columns[this.selectedColIndex];
          const rowCategory = this.gameData.rows[this.selectedRowIndex];

          const isColNull = await this.checkForNullCategoryData(colCategory.id, code);
          if (isColNull) {
            this.showNoDataPopup(colCategory.name, guess);
            return;
          }

          const isRowNull = await this.checkForNullCategoryData(rowCategory.id, code);
          if (isRowNull) {
            this.showNoDataPopup(rowCategory.name, guess);
            return;
          }

          // if answer is wrong but accepted, save to wrongGuesses
          this.wrongGuesses.push({ index, code, name: guess })
        }

        this.guessedCountries.push(guess);

        // Update rarity
        if (correct) {
          const url = `${config.baseURL}/game/rarity/${this.boardId}?guess=${guess}&index=${index}`;
          try {
            axios.post(url);
          } catch (error) {
            console.error('Error making request:', error);
          }

          //calculate rarity of guess
          const total = this.liveRarityData['match_box_' + index]['total'];
          const count = this.liveRarityData['match_box_' + index][guess];
          let rarityPercentage;

          rarityPercentage = ((count / total) * 100).toFixed(1);
          if (isNaN(rarityPercentage)) {
            rarityPercentage = 0;
          }
          this.score = (this.score - (100 - rarityPercentage)).toFixed(1);

          // Ensure rarityPercentages[this.selectedRowIndex] is initialized as an array
          if (!this.rarityPercentages[this.selectedRowIndex]) {
            this.rarityPercentages[this.selectedRowIndex] = [];
          }

          // Set rarityPercentage value
          this.rarityPercentages[this.selectedRowIndex][this.selectedColIndex] =
            rarityPercentage;
          this.board[this.selectedRowIndex][this.selectedColIndex] = guess;
          this.getImageUrl(guess);
        }

        // Hide the search popup
        this.hideSearch();
        if (!this.infiniteMode) {
          this.guesses--;
        }

        // Show wrong animation on box
        if (!correct) {
          this.setWrongGuessLocation();
        }

      // Check if the game is won or lost
      if (this.checkIfWon()) {
        this.showGameOver();
        this.isGameOver = true;
      } else if (this.guesses === 0) {
        // this.getLiveRarity()
        this.showGameOver();
        this.isGameOver = true;
      }
    } catch (error) {
      console.error('Error making guess:', error);
    }finally {
      // Reset the flag when the guess request is complete
      this.isGuessing = false;
    }
    this.saveDataToLocalStorage();
  },

    // Check if country has been used
    checkIfCountryHasBeenUsed(guess) {
      if (this.board.flat().some(box => box === guess)) {
        return true;
      } else {
        return false;
      }
    },

    setWrongGuessLocation() {
      this.wrongGuessLocation = [this.selectedRowIndex, this.selectedColIndex];
      setTimeout(() => {
        this.wrongGuessLocation = [];
      }, 500)
    },

    // Check if the game is won
    checkIfWon() {
      if (this.board.flat().every(box => box !== '')) {
        // this.getLiveRarity();
        return true;
      } else {
        return false;
      }
    },

    // Get the image URL of the guessed country
    getImageUrl,

    // Give up the game
    giveUp() {
      // this.getLiveRarity();
      this.isGameOver = true;
      this.saveDataToLocalStorage();
      this.showGameOverPopup = true;
    },

    // Restart the game or give up based on the game state
    restartOrGiveUp() {
      if (this.isGameOver) {
        this.restartGame();
        // this.getLiveRarity();
      } else {
        this.giveUp();
      }
    },

    // Initialize rarity percentages array
    initializeRarityPercentages() {
      this.rarityPercentages = this.board.map(row => Array(row.length).fill(''));
    },

    // Function to get the rarity text based on the percentage
    getRarityText(rowIndex, colIndex) {
      const rarityPercentage = this.rarityPercentages[rowIndex][colIndex];
      if (rarityPercentage === 0) {
        return 'Legendary - First';
      } else {
        const parsedPercentage = parseFloat(rarityPercentage);
        if (!isNaN(parsedPercentage)) {
          if (parsedPercentage >= 30) {
            return `Common - ${parsedPercentage}%`;
          } else if (parsedPercentage >= 20) {
            return `Uncommon - ${parsedPercentage}%`;
          } else if (parsedPercentage >= 10) {
            return `Rare - ${parsedPercentage}%`;
          } else if (parsedPercentage >= 5) {
            return `Epic - ${parsedPercentage}%`;
          } else {
            return `Legendary - ${parsedPercentage}%`;
          }
        } else {
          return `Legendary - ${rarityPercentage}`;
        }
      }
    },

    // Function to get the rarity styling based on the percentage
    getRarityStyle(rowIndex, colIndex) {
      const rarityPercentage = parseFloat(this.rarityPercentages[rowIndex][colIndex]);
      if (rarityPercentage >= 30) {
        return { backgroundColor: 'rgb(101, 208, 101)' };
      } else if (rarityPercentage >= 20) {
        return { backgroundColor: 'rgb(101, 139, 208)' };
      } else if (rarityPercentage >= 10) {
        return { backgroundColor: 'rgb(150, 112, 221)' };
      } else if (rarityPercentage >= 5) {
        return { backgroundColor: 'rgb(221, 112, 197)' };
      } else {
        return { backgroundColor: 'rgb(225, 53, 53)' };
      }
    },

    // Check if any guess has been placed
    anyGuessPlaced() {
      return this.guessedCountries.length > 0;
    },


    // Save the game data to local storage
    saveDataToLocalStorage() {
      // Get existing user data from localStorage
      const userDataString = localStorage.getItem('userData');
      let userData = {};

      // Parse the JSON string to get the saved data object
      if (userDataString) {
        userData = JSON.parse(userDataString);
      }

      // Check if data for the current boardId already exists
      if (!userData[this.boardId]) {
        userData[this.boardId] = {}; // If not, create a new object for the boardId
      }

      // Update the component's state in the userData object
      userData[this.boardId] = {
        guessedCountries: this.guessedCountries,
        guesses: this.guesses,
        isGameOver: this.isGameOver,
        board: this.board,
        rarityPercentages: this.rarityPercentages,
        score: this.score,
        wrongGuesses: this.wrongGuesses,
        // Add other relevant data properties here
      };

      // Save the updated userData object to localStorage
      localStorage.setItem('userData', JSON.stringify(userData));
      setData("userData", userData);
    },

    // Load the game data from local storage
    loadDataFromLocalStorage() {

      // Check if there is saved data for the current boardId in local storage
      const userDataString = localStorage.getItem('userData');
      if (userDataString) {
        // Parse the JSON string to get the saved data object
        const userData = JSON.parse(userDataString);
        if (userData[this.boardId]) { // Check if data for current boardId exists
          // Update the component's state with the loaded data
          const savedData = userData[this.boardId];
          this.guessedCountries = savedData.guessedCountries;
          this.guesses = savedData.guesses;
          this.isGameOver = savedData.isGameOver;
          this.infiniteMode = savedData.infiniteMode;
          this.board = savedData.board;
          this.rarityPercentages = savedData.rarityPercentages;
          this.score = savedData.score;
          this.wrongGuesses = savedData.wrongGuesses ?? [];
          // Add other relevant data properties here
        } else {
          // Reset component's state if no data is found for the current boardId
          this.resetState();
        }
      } else {
        // Reset component's state if no data is found in local storage
        this.resetState();
      }
    },

    // Delete user data for the current board from local storage
    deleteUserDataForCurrentBoard() {
      const userDataString = localStorage.getItem('userData');
      const userLeaderboardDataString = localStorage.getItem('leaderboard_data_' + this.boardId);

      if (userDataString) {
        const userData = JSON.parse(userDataString);
        if (userData[this.boardId]) {
          delete userData[this.boardId];
          localStorage.setItem('userData', JSON.stringify(userData));
        }
      }

      if (userLeaderboardDataString) {
        localStorage.removeItem('leaderboard_data_' + this.boardId);
      }
    },

    // Reset the component's state
    resetState() {
      this.guessedCountries = [];
      this.guesses = 10;
      this.wrongGuesses = [];
      this.isGameOver = false;
      this.infiniteMode = false;
      this.board = [
        ['', '', ''],
        ['', '', ''],
        ['', '', '']
      ];
      this.rarityPercentages = [];
      this.score = 900;
    },

    sendEmail() {
      // Prompt user to send email
      const email = 'geogridgame@gmail.com';
      const mailtoLink = `mailto:${email}`;

      window.location.href = mailtoLink;
    },
    isFirefox() {
      return navigator.userAgent.includes('Firefox');
    },
  }
};
</script>

<style>
:root {
  background-color: rgb(53, 53, 72);
  --cell-width: 150px;
  --cell-width-tablet: 120px;
  --cell-width-mobile: 90px;
}

.container {
  max-width: 1500px;
  margin: 0 auto;
  position: relative;
}

.page-title {
  margin-bottom: -1px;
  color: rgb(223, 223, 223);
}

.logo {
  width: 200px;
  max-width: 100%;
}

.logo img{
  width: 100%;
  height: auto;
}

.filler{
  width: var(--cell-width);
}

.prompt {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(223, 223, 223);
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.1);
  width: var(--cell-width);
  min-height: 60px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
  }
  
  .prompt:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.row-prompt{
  height: var(--cell-width);
}

.board {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 150px;
  gap: 4px;
}

.row {
  display: flex;
  align-items: stretch;
  height: var(--cell-width);
  gap: 4px;
}

.row:first-child{
  height: max-content;
}

.box {
  width: var(--cell-width);
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.25s;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 6px;
}
.btn{
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
  color: #fff;
  border: none;
  padding: 6px 12px;
  border-radius: 5px;
  transition: background-color 0.3s;
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
}

.guessed{
  cursor: initial;
}
.box:not(.guessed):hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.highlighted {
  background-color: rgb(252, 252, 0);
}

.wrong {
  background-color: #AA0817;
  transition: background-color 0.25s;
}
.prev-grids-btn {
  margin-top: 10px;
  margin-bottom: 20px;
 
}

.restart-btn, .infinity-btn, .show-summary-btn {
  margin-bottom: 15px;
}

.restart-btn, .show-summary-btn {
  margin-left: 10px;
}

.btn:hover {
  background-color: rgb(223, 223, 223);
}
.flag-container{
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  margin: 6px 0;
}
.country-flag {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.country-info {
  width: 100%;
  min-height: 14%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  box-sizing: border-box;
  overflow: hidden;
  font-size: 12px;
  color: white;
  border-radius: 3px;
  flex-shrink: 0;
  background-color: #373768;
}
.rarity-element {
  width: 100%;
  height: 11%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 10px;
  font-weight: bold;
  border-radius: 3px;
  
}

.guesses-and-score {
  margin-bottom: 5px;
  margin-right: 20px;
  color: rgb(223, 223, 223);
}

.top-nav {
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  max-width: 1500px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  z-index: 100;
  padding: 24px 1.5rem;

}
.top-nav-inner {
  display: flex;
  gap: 0.5rem;
}

.nav-btn {
  padding: 5px;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  position: relative;
}

.nav-btn:hover {
  background-color: rgb(223, 223, 223);
}

.nav-btn.avatar {
  width: 32px;
  background-size: cover;
  background-position: center;
}

.tooltip {
  position: absolute;
  background: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.4s;
  pointer-events: none;
}

.nav-btn:hover .tooltip {
  opacity: 1;
}

.category-help-msg {
  color: rgb(134, 136, 164);
  font-size: 12px;
  margin-top: 1px;
  font-weight: bold;
}

.llc-msg {
  color: rgb(134, 136, 164);
  font-size: 12px;
  font-weight: bold;
}

.contact-msg {
  color: rgb(134, 136, 164);
  font-size: 12px;
  font-weight: bold;
  margin-left: 20px;
}

.plays-msg {
  color: rgb(134, 136, 164);
  font-size: 14px;
  margin-top: 2px;
  margin-bottom: 2px;
  font-weight: bold;
}

@media screen and (max-width: 992px) {
  .top-nav {
    position: static;
    left: 0;
    transform: none;
  }
  .page-title{
    margin: 0;
  }
  .board{
    margin-right: 0;
    margin-left: -25px;
  }
  .guesses-and-score{
    margin-right: 0;
  }
}

@media screen and (max-width: 768px) {
  .plays-msg {
    font-size: 12px;
  }
  .llc-msg,
  .contact-msg {
    font-size: 10px;
  }
  .board{
    gap: 3px;
  }
  .filler{
    width: var(--cell-width-tablet);
  }
  .prompt {
    overflow: hidden;
    width: var(--cell-width-tablet);
    min-height: 50px;
    font-size: 12px;
  }
  .row-prompt{
  height: var(--cell-width-tablet);
  }
  .row {
    height: var(--cell-width-tablet);
    gap: 3px;
  }

  .box {
    width: var(--cell-width-tablet);
    font-size: 14px;
    padding: 4px;
  }

  .country-info {
    font-size: 10px;
  }

  .rarity-element {
    font-size: 8px;
  }

  .highlighted {
    background-color: rgb(230, 230, 123) !important;
  }

  .flag-container{
    margin: 4px 0;
  }
}

@media screen and (max-width: 600px) {
  .top-nav {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .board{
    margin: 0;
    gap: 2px;
  }
  .filler{
    width: var(--cell-width-mobile);
  }
  .prompt {
    width: var(--cell-width-mobile);
    font-size: 10px;
    padding: 8px;
  }
  .row-prompt {
    height: var(--cell-width-mobile);
  }
  .row {
    height: var(--cell-width-mobile);
    gap: 2px;
  }
  .box {
    width: var(--cell-width-mobile);
  }
  .guesses-and-score{
    font-size: 14px;
  }
  .country-info {
    font-size: 8px;
  }

  .rarity-element {
    font-size: 7px;
  }
}

@media screen and (max-width: 375px) {
  .filler{
    width: 80px;
  }
  .prompt {
    width: 80px;
  }
  .row-prompt {
    height: 80px;
  }
  .row {
    height: 80px;
  }
  .box {
    width: 80px;
  }
  .page-title {
    font-size: 26px;
  }
}
@keyframes shake {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(-8px);
  }

  40% {
    transform: translateX(8px);
  }

  60% {
    transform: translateX(-8px);
  }

  80% {
    transform: translateX(8px);
  }

  100% {
    transform: translateX(0);
  }
}

.animate-shake {
  animation: shake 0.5s;
}

@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>