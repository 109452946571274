<script>
export default {
  async mounted() {
    // If there is userData in the hash, then decode it
    try {
      const hash = window.location.hash;
      if (hash.startsWith("#userData=")) {
        const rawUserData = decodeURIComponent(
          hash.substring("#userData=".length)
        );
        const result = JSON.parse(rawUserData);
        localStorage.setItem("token", result.token);
        localStorage.setItem("refreshToken", result.refreshToken);
        localStorage.setItem("refreshTime", Date.now());
      }
    } catch (e) {
      console.error("Error decoding user data:", e);
    }

    window.location.href = "/";
  },
};
</script>
