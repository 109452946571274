import { getCities, getCountryData } from './fetchDataFromCDN';

export const getExplanationText = async (country, category) => {
  switch (category.id) {
    case 'color_on_flag':
    case 'flag_rwb':
    case 'flag_without_rwb':
      return `Colors on flag: ${country.geoGridData.flagInfo.colorsOnFlag.join(
        ', '
      )}`;
    case 'flag_has_x_colors':
    case 'flag_has_x_or_more_colors':
      return `Number of colors on flag: ${country.geoGridData.flagInfo.colorsOnFlag.length}`;
    case 'flag_has_star':
      return `${
        country.geoGridData.flagInfo.hasStar
          ? 'Flag has star/sun'
          : 'Flag does not have star/sun'
      }`;
    case 'flag_has_coat_of_arms':
      return `${
        country.geoGridData.flagInfo.hasCoatOfArms
          ? 'Flag has a coat of arms'
          : 'Flag does not have a coat of arms'
      }`;
    case 'flag_has_animal':
      return `${
        country.geoGridData.flagInfo.hasAnimal
          ? 'Flag has an animal'
          : 'Flag does not have an animal'
      }`;
    case 'island_nation':
      return `${
        country.geoGridData.geographyInfo.islandNation
          ? 'Island nation'
          : 'Not an island nation'
      }`;
    case 'landlocked':
      return `${
        country.geoGridData.geographyInfo.landlocked
          ? 'Is landlocked'
          : 'Is not landlocked'
      }`;
    case 'coastline_length_over_x':
    case 'coastline_length_under_x':
      return `Coastline length: ${country.geoGridData.geographyInfo.coastlineLength.toLocaleString()} km`;
    case 'coastline_on_x': {
      const coasts = country.geoGridData.geographyInfo.coastline;
      return `Coastline on: ${coasts.length > 0 ? coasts.join(', ') : 'N/A'}`;
    }
    case 'touches_sahara':
      return `${
        country.geoGridData.geographyInfo.touchesSahara
          ? 'Touches the Sahara Desert'
          : 'Does not touch the Sahara Desert'
      }`;
    case 'continent':
      return `Continent(s): ${country.countryData.continent
        .map((c) => continents[c])
        .join(', ')}`;
    case 'river_x_runs_through': {
      const rivers = country.geoGridData.geographyInfo.rivers;
      return `River(s): ${rivers.length > 0 ? rivers.join(', ') : 'N/A'}`;
    }
    case 'touches_eurasian_steppe':
      return `${
        country.geoGridData.geographyInfo.touchesEurasionSteppe
          ? 'Touches the Eurasian Steppe'
          : 'Does not touch the Eurasian Steppe'
      }`;
    case 'touches_equator':
      return `${
        country.geoGridData.geographyInfo.touchesEquator
          ? 'Touches the Equator'
          : 'Does not touch the Equator'
      }`;
    case 'top_10_lakes':
      return `${
        country.geoGridData.geographyInfo.top10Lakes
          ? 'Top 10 in number of lakes'
          : 'Not top 10 in number of lakes'
      }`;
    case 'hdi_over_x':
    case 'hdi_under_x':
      return `Human Development Index: ${
        country.geoGridData.economicInfo.HDI ?? 'N/A'
      }`;
    case 'gdp_per_capita_over_x':
    case 'gdp_per_capita_under_x': {
      const gdp = country.geoGridData.economicInfo.GDPPerCapita;
      return `GDP per capita: ${gdp ? `$${gdp.toLocaleString()}` : 'N/A'}`;
    }
    case 'top_20_wheat_production':
      return `${
        country.geoGridData.economicInfo.top20WheatProduction
          ? 'Top 20 in wheat production'
          : 'Not top 20 in wheat production'
      }`;
    case 'top_20_oil_production':
      return `${
        country.geoGridData.economicInfo.top20OilProduction
          ? 'Top 20 in oil production'
          : 'Not top 20 in oil production'
      }`;
    case 'top_20_renewable_electricity_production':
      return `${
        country.geoGridData.economicInfo.top20RenewableElectricityProduction
          ? 'Top 20 in renewable electricity production'
          : 'Not top 20 in renewable electricity production'
      }`;
    case 'nuclear_power':
      return `${
        country.geoGridData.economicInfo.producesNuclearPower
          ? 'Produces nuclear power'
          : 'Does not produce nuclear power'
      }`;
    case 'is_monarchy':
      return `${
        country.geoGridData.politicalInfo.isMonarchy
          ? 'Is a monarchy'
          : 'Is not a monarchy'
      }`;
    case 'in_eu':
      return `${
        country.geoGridData.politicalInfo.inEU
          ? 'Member of the European Union'
          : 'Not a member of the European Union'
      }`;
    case 'has_nuclear_weapons':
      return `${
        country.geoGridData.politicalInfo.hasNuclearWeapons
          ? 'Has nuclear weapons'
          : 'Does not have nuclear weapons'
      }`;
    case 'was_ussr':
      return `${
        country.geoGridData.politicalInfo.wasUSSR
          ? 'Was part of the USSR'
          : 'Was not part of the USSR'
      }`;
    case 'commonwealth_member':
      return `${
        country.geoGridData.politicalInfo.inCommonwealth
          ? 'Member of the Commonwealth'
          : 'Not a member of the Commonwealth'
      }`;
    case 'official_language': {
      const languages = country.geoGridData.politicalInfo.officialLanguageCodes;
      return `Offical language(s): ${
        languages.length > 0
          ? languages
              .map(
                (c) => officialLanguageOptions.find((o) => o.code === c)?.name
              )
              .join(', ')
          : 'N/A'
      }`;
    }
    case 'observes_x_time_zone':
    case 'multiple_time_zones': {
      const timezones = country.geoGridData.politicalInfo.timeZones;
      return `Observed timezone(s): ${
        timezones.length > 0 ? timezones.join(', ') : 'N/A'
      }`;
    }
    case 'observes_dst':
      return `${
        country.geoGridData.politicalInfo.observesDST
          ? 'Observes Daylight Savings Time'
          : 'Does not observe Daylight Savings Time'
      }`;
    case 'cpi_over_x':
    case 'cpi_under_x':
      return `Corruption Perceptions Index: ${
        country.geoGridData.politicalInfo.CPI ?? 'N/A'
      }`;
    case 'same_sex_marriage_legal':
      return `${
        country.geoGridData.politicalInfo.sameSexMarriageLegal
          ? 'Same-sex marriage legalised'
          : 'Same-sex marriage not legalised'
      }`;
    case 'same_sex_activities_illegal':
      return `${
        country.geoGridData.politicalInfo.sameSexActivitiesIllegal
          ? 'Same-sex activities are illegal'
          : 'Same-sex activities are not illegal'
      }`;
    case 'is_territory':
      return `${
        country.geoGridData.politicalInfo.isTerritory
          ? 'Is a dependency/territory'
          : 'Is not a dependency/territory'
      }`;
    case 'olympic_medals_over_x':
    case 'olympic_medals_under_x':
    case 'no_olympic_medals':
      return `Olympic medals: ${country.geoGridData.sportsInfo.olympicMedals}`;
    case 'hosted_olympics':
      return `${
        country.geoGridData.sportsInfo.hostedOlympics
          ? 'Has hosted the Olympics'
          : 'Has not hosted the Olympics'
      }`;
    case 'hosted_f1':
      return `${
        country.geoGridData.sportsInfo.hostedF1
          ? 'Has hosted a Formula 1 Grand Prix'
          : 'Has not hosted a Formula 1 Grand Prix'
      }`;
    case 'hosted_world_cup':
      return `${
        country.geoGridData.sportsInfo.hostedMensWorldCup
          ? "Has hosted the Men's FIFA World Cup"
          : "Has not hosted the Men's FIFA World Cup"
      }`;
    case 'played_world_cup':
      return `${
        country.geoGridData.sportsInfo.playedMensWorldCup
          ? "Has played in the Men's FIFA World Cup"
          : "Has not played in the Men's FIFA World Cup"
      }`;
    case 'won_world_cup':
      return `${
        country.geoGridData.sportsInfo.wonMensWorldCup
          ? "Has won the Men's FIFA World Cup"
          : "Has not won the Men's FIFA World Cup"
      }`;
    case 'drives_left':
      return `${
        country.geoGridData.factsInfo.drivesLeft
          ? 'Drives on the left'
          : 'Does not drive on the left'
      }`;
    case 'alcohol_ban':
      return `${
        country.geoGridData.factsInfo.hasAlcoholBan
          ? 'Alcohol prohibition'
          : 'No alcohol prohibition'
      }`;
    case '50_plus_skyscrapers':
      return `${
        country.geoGridData.factsInfo.has50Skyscrapers
          ? 'Has 50+ skyscrapers'
          : 'Does not have 50+ skyscrapers'
      }`;
    case 'top_20_obesity_rate':
      return `${
        country.geoGridData.factsInfo.top20ObesityRate
          ? 'Top 20 in obesity rate'
          : 'Not top 20 in obesity rate'
      }`;
    case 'top_20_chocolate_consumption':
      return `${
        country.geoGridData.factsInfo.top20ChocolateConsumption
          ? 'Top 20 in chocolate consumption per capita'
          : 'Not top 20 in chocolate consumption per capita'
      }`;
    case 'top_20_alcohol_consumption':
      return `${
        country.geoGridData.factsInfo.top20AlcoholConsumption
          ? 'Top 20 in alcohol consumption per capita'
          : 'Not top 20 in alcohol consumption per capita'
      }`;
    case 'top_20_population_density':
      return `${
        country.geoGridData.factsInfo.top20PopulationDensity
          ? 'Top 20 in population density'
          : 'Not top 20 in population density'
      }`;
    case 'bottom_20_population_density':
      return `${
        country.geoGridData.factsInfo.bottom20PopulationDensity
          ? 'Bottom 20 in population density'
          : 'Not bottom 20 in population density'
      }`;
    case 'top_20_tourism':
      return `${
        country.geoGridData.factsInfo.top20TourismRate
          ? 'Top 20 in annual tourist arrivals'
          : 'Not top 20 in annual tourist arrivals'
      }`;
    case 'top_20_rail_size':
      return `${
        country.geoGridData.factsInfo.top20RailSize
          ? 'Top 20 in rail transport network size'
          : 'Not top 20 in rail transport network size'
      }`;
    case 'top_20_world_hertitage_sites':
      return `${
        country.geoGridData.factsInfo.top20WorldHeritageSites
          ? 'Top 20 in number of World Heritage sites'
          : 'Not top 20 in number of World Heritage sites'
      }`;
    case 'air_pollution_over_x':
    case 'air_pollution_under_x': {
      const airPollution = country.geoGridData.factsInfo.airPollution;
      return `Air pollution: ${airPollution ? `${airPollution} μg/m³` : 'N/A'}`;
    }
    case 'co2_emissions_per_capita_over_x':
    case 'co2_emissions_per_capita_under_x': {
      const co2 = country.geoGridData.factsInfo.co2Emissions;
      return `CO₂ emissions per capita: ${co2 ? `${co2} tCO₂/year` : 'N/A'}`;
    }
    case 'population_over_x':
    case 'population_under_x':
      return `Population: ${country.countryData.population.toLocaleString()}`;
    case 'size_over_x':
    case 'size_under_x':
      return `Size: ${country.countryData.size.toLocaleString()} km²`;
    case 'capital_population_over_x':
    case 'captial_population_under_x': {
      const cities = await getCities();
      const capitalCities = cities.filter(
        (c) =>
          c.countryCode.toUpperCase() === country.countryCode.toUpperCase() &&
          c.capital &&
          c.population
      );
      return `Capital city population(s): ${
        capitalCities.length
          ? `${capitalCities
              .map((c) => `${c.name} - ${c.population.toLocaleString()}`)
              .join(', ')}`
          : 'N/A'
      }`;
    }
    case 'capital_not_most_populated_city': {
      const cities = await getCities();
      const countryCities = cities
        .filter(
          (c) =>
            c.countryCode.toUpperCase() === country.countryCode.toUpperCase() &&
            c.population
        )
        .sort((a, b) => a.population - b.population);
      const capitalNotMostPopulated =
        countryCities.length > 1 &&
        !countryCities[countryCities.length - 1]?.capital;
      return `${
        capitalNotMostPopulated
          ? 'Capital is not the most populated city'
          : 'Capital is the most populated city'
      }`;
    }
    case 'starting_letter':
      return `Starting letter: ${country.countryName
        .charAt(0)
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toUpperCase()}`;
    case 'ending_letter':
      return `Ending letter: ${country.countryName
        .charAt(country.countryName.length - 1)
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toUpperCase()}`;
    case 'name_length':
    case 'name_x_plus_letters_long':
      return `Name length: ${
        country.countryName.replace(/[- '()]/g, '').length
      }`;
    case 'name_multiple_words': {
      const isMultiple = country.countryName.split(/[- ]/g).length > 1;
      return `${
        isMultiple
          ? 'Name consists of multiple words'
          : 'Name does not consist of multiple words'
      }`;
    }
    case 'name_start_end_same_letter': {
      const startsAndEndsWithSameLetter =
        country.countryName.charAt(0).toUpperCase() ==
        country.countryName
          .charAt(country.countryName.length - 1)
          .toUpperCase();
      return `${
        startsAndEndsWithSameLetter
          ? 'Starts and ends with the same letter'
          : 'Does not start and end with the same letter'
      }`;
    }
    case 'borders_x_to_y':
    case 'borders_x_or_more': {
      let borderCount = 0;
      if (country.geoGridData.geographyInfo.borderCountOverride) {
        borderCount = country.geoGridData.geographyInfo.borderCountOverride;
      } else if (country.countryData.borderMode === 'nearby') {
        borderCount = 0;
      } else {
        const borderCodes = country.countryData.borders;
        const borderData = await Promise.all(
          borderCodes.map((b) => getCountryData(b))
        );
        borderCount = borderData.filter(
          (b) => b.borderMode !== 'nearby'
        ).length;
      }
      return `Border count: ${borderCount}`;
    }
    case 'borders_x': {
      const borderOption = borderCountryOptions.find(
        (v) => v.id === category.variantId
      );
      if (!borderOption) return '';
      const bordersX =
        country.countryData.borderMode === 'bordering' &&
        country.countryData.borders.includes(borderOption.code.toLowerCase());
      return `${
        bordersX
          ? `Borders ${borderOption.name}`
          : `Does not border ${borderOption.name}`
      }`;
    }
    default:
      return '';
  }
};

// for 'borders_x' category
const borderCountryOptions = [
  { id: 0, name: 'Russia', code: 'RU' },
  { id: 1, name: 'China', code: 'CN' },
  { id: 2, name: 'Brazil', code: 'BR' },
  { id: 3, name: 'France', code: 'FR' },
];

// for 'continent' category
const continents = {
  AS: 'Asia',
  EU: 'Europe',
  AF: 'Africa',
  NA: 'North America',
  SA: 'South America',
  AN: 'Antarctica',
  OC: 'Oceania',
};

// for 'official_language' category
export const officialLanguageOptions = [
  {
    id: 0,
    name: 'Arabic',
    code: 'ara',
  },
  {
    id: 1,
    name: 'Spanish',
    code: 'spa',
  },
  {
    id: 2,
    name: 'French',
    code: 'fra',
  },
  {
    id: 3,
    name: 'English',
    code: 'eng',
  },
  {
    id: 4,
    name: 'Portuguese',
    code: 'por',
  },
];