<template>
  <div v-if="localLiveRarityData" class="modal-bg" @click.self="$emit('close')">
    <div class="content">
      <h1 class="game-summary-title">Game Summary</h1>
      <div class="close-btn" @click="$emit('close')">
        <div />
        <div />
      </div>

      <div class="game-summary-come-back-msg">⏰ Come back to play the new board tomorrow! ⏰</div>
      <div class="board-title">🌎 Board #{{ boardId }} 🌎</div>
      <div class="stats-container">
        <div class="board-info">
          <div class="emoji-grid">
            <span v-for="(row, rowIndex) in board" :key="rowIndex" style="min-width: 200px;">
              {{ row.map(cell => (cell === '' ? '❌' : '✅')).join('') }}
              <br v-if="rowIndex !== board.length - 1">
            </span>
          </div>
        </div>
        <div class="score-container">
          <div class="score-text">Your Score</div>
          <h3>
            <p class="score-rank"><span>Score: </span><span style="font-weight: normal;">{{ score }}</span></p>
            <p class="rank-rank"><span >Rank: </span><span style="font-weight: normal;">{{ formatNumber(rank) }} / {{ formatNumber(plays) }}</span></p>
          </h3>
          <div class="button-container">
            <button ref="shareButton" class="share-btn" @click="copyText($event)">Share</button>
          </div>
        </div>
      </div>

      <!-- Most Popular Answers: index 0 -->
      <div class="accordian-panel">
        <div class="accordian-header" @click="toggle(0)">
          Most Popular Answers
          <span class="chevron" :class="{ 'chevron-up': !isActive(0) }">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" stroke-width="1"
              stroke="currentColor" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
            </svg>
          </span>
        </div>
        <transition name="slide-fade">
          <div v-if="isActive(0)" class="accordian-content">
            <popularAnswerBoard class="popular-answer-board" :score="score" :boardId="boardId" :board="board"
            :liveRarityData="liveRarityData" :gameData="gameData" :countryData="countryData" :answersList="answersList"></popularAnswerBoard>
          </div>
        </transition>
      </div>

      <!-- Least Popular Answers: index 1 -->
      <div class="accordian-panel">
        <div class="accordian-header" @click="toggle(1)">
          Least Popular Answers
          <span class="chevron" :class="{ 'chevron-up': !isActive(1) }">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" stroke-width="1"
              stroke="currentColor" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
            </svg>
          </span>
        </div>
        <transition name="slide-fade">
          <div v-if="isActive(1)" class="accordian-content">
            <leastCommonAnswers class="popular-answer-board" :score="score" :boardId="boardId" :board="board"
              :liveRarityData="liveRarityData" :gameData="gameData" :countryData="countryData" :answersList="answersList"></leastCommonAnswers>
          </div>
        </transition>
      </div>

      <!-- Answer Frequency: index 2 -->
      <div class="accordian-panel">
        <div class="accordian-header" @click="toggle(2)">
          Answer Frequency
          <span class="chevron" :class="{ 'chevron-up': !isActive(2) }">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" stroke-width="1"
              stroke="currentColor" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
            </svg>
          </span>
        </div>
        <transition name="slide-fade">
          <div v-if="isActive(2)" class="accordian-content">
            <answerFreq class="popular-answer-board" :score="score" :board="board" :boardId="+boardId"
              :localLiveRarityData="localLiveRarityData" :gameData="gameData" :plays="plays"></answerFreq>
          </div>
        </transition>
      </div>

      <!-- Board Answers: index 3 -->
      <div class="accordian-panel">
        <div class="accordian-header" @click="toggle(3)">
          Board Answers
          <span class="chevron" :class="{ 'chevron-up': !isActive(3) }">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" stroke-width="1"
              stroke="currentColor" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
            </svg>
          </span>
        </div>
        <transition name="slide-fade">
          <div v-if="isActive(3)" class="accordian-content">
            <showAnswersBoard class="popular-answer-board" :board="board" :gameData="gameData" :answersList="answersList"></showAnswersBoard>
          </div>
        </transition>
      </div>

      <!-- Wrong Answers: index 4 -->
      <div v-if="wrongGuesses.length" class="accordian-panel">
        <div class="accordian-header" @click="toggle(4)">
          <div class="new-header">
            Your Wrong Answers
            <div v-if="Date.now() < 1740787200000" class="new-label">New!</div>
          </div>
          <span class="chevron" :class="{ 'chevron-up': !isActive(4) }">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" stroke-width="1"
              stroke="currentColor" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
            </svg>
          </span>
        </div>
        <transition name="slide-fade">
          <div v-if="isActive(4)" class="accordian-content">
            <wrongAnswersBoard class="popular-answer-board" :board="board" :gameData="gameData" :wrongGuesses="wrongGuesses"></wrongAnswersBoard>
          </div>
        </transition>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import popularAnswerBoard from './boards/popularAnswerBoard.vue';
import answerFreq from './boards/answerFreq.vue';
import showAnswersBoard from './boards/showAnswersBoard.vue';
import leastCommonAnswers from './boards/leastCommonAnswers.vue';
import wrongAnswersBoard from './boards/wrongAnswersBoard.vue';
import config from '../../axiospath.js';

export default {
  components: {
    popularAnswerBoard,
    answerFreq,
    showAnswersBoard,
    leastCommonAnswers,
    wrongAnswersBoard,
  },
  props: ['score', 'boardId', 'board', 'liveRarityData', 'gameData', 'countryData', 'answersList', 'wrongGuesses'],
  data() {
    return {
      rank: null,
      plays: null,
      localLiveRarityData: JSON.parse(localStorage.getItem(`answer_freq_data_${this.boardId}`)),
      activeIndexes: [], // boards
    };
  },
  methods: {
    closeGameOverPopup() {
      this.$emit('close');
    },
    formatNumber(number) {
      if (number === null) return ''; // Return an empty string if the number is null
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    // Copy the game summary to the clipboard
    copyText(event) {
      event.preventDefault();

      const gridEmojis = this.board.map(row => row.map(cell => cell === '' ? '❌' : '✅').join(' ')).join('\n') + '\n\n';
      const textToCopy = `${gridEmojis}🌎Game Summary🌎\nBoard #${this.boardId}\nScore: ${this.score}\nRank: ${this.formatNumber(this.rank)} / ${this.formatNumber(this.plays)}\nhttps://geogridgame.com\n@geogridgame`;
      
      const textArea = document.createElement('textarea');
      textArea.value = textToCopy;
      document.body.appendChild(textArea);
      textArea.select();

      try {
        document.execCommand('copy');
        this.$refs.shareButton.innerText = 'Copied to clipboard!';
      } catch (err) {
        console.error('Unable to copy text', err);
      }

      document.body.removeChild(textArea);
    },

    // Set the leaderboard data
    async setLeaderboardData() {
      try {
        // Check if leaderboard data exists in local storage
        const localLeaderboardData = localStorage.getItem(`leaderboard_data_${this.boardId}`);

        if (localLeaderboardData) {
          const parsedData = JSON.parse(localLeaderboardData);
          this.rank = parsedData.rank;
          this.plays = parsedData.plays;
          return;
        }

        // Fetch leaderboard data if not found in local storage
        const response = await axios.post(`${config.baseURL}/game/stats/${this.boardId}?score=${this.score}&flag=0`);
        const scores = response.data.scores;
        const scoreRank = scores.indexOf(String(this.score)) + 1;


        const plays = response.data.plays;
        this.rank = scoreRank;
        this.plays = plays;

        // Save leaderboard data to local storage
        localStorage.setItem(`leaderboard_data_${this.boardId}`, JSON.stringify({ rank: scoreRank, plays }));
      } catch (error) {
        console.error('Error setting leaderboard data:', error);
      }
    },
    async setAnswerFrequencyData() {
  try {
    // Check if answer frequency data exists in local storage
    const localAnswerFreqData = localStorage.getItem(`answer_freq_data_${this.boardId}`);

    if (localAnswerFreqData) {
      this.localLiveRarityData = JSON.parse(localAnswerFreqData);
    } else {
      // Save the current value of liveRarityData
      const liveRarityData = this.liveRarityData;

      // Set the local storage item with liveRarityData
      localStorage.setItem(`answer_freq_data_${this.boardId}`, JSON.stringify(liveRarityData));
      
      // Update localLiveRarityData with the value from localStorage
      this.localLiveRarityData = liveRarityData;
    }
    } catch (error) {
      console.error('Error setting answer frequency data:', error);
    }
  },
  toggle(index) {
      const i = this.activeIndexes.indexOf(index);
      if (i > -1) {
        this.activeIndexes.splice(i, 1);
      } else {
        this.activeIndexes.push(index);
      }
    },
    isActive(index) {
      return this.activeIndexes.includes(index);
    },
  },

  mounted() {
    this.localLiveRarityData = JSON.parse(localStorage.getItem(`answer_freq_data_${this.boardId}`));
    this.setLeaderboardData();
    this.setAnswerFrequencyData();
  }
}
</script>

<style scoped>
.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.game-summary-title {
  font-weight: 600;
  font-size: 1.75rem;
  margin: 0;
}

.game-summary-come-back-msg{
  font-size: 1rem;
  font-weight: 600;
  margin: 1rem 0;
}

.section-title{
  margin: 50px 0;
}

.content {
  position: relative;
  background-color: #353548;
  padding: 40px;
  border-radius: 16px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  text-align: center;
  max-width: 600px;
  max-height: 70%;
  overflow-y: auto;
  color: white;

  /* hide scrollbar */
  ::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
  -ms-overflow-style: none;
}


button {
  padding: 10px 20px;
  font-size: 16px;
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
  background-color: rgba(255, 255, 255, 0.5);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #bbb9b9;
}

.score-rank,.rank-rank{
  display: flex; 
  justify-content: space-between;
  margin: 0;
}

.board-title{
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.stats-container{
  display: flex;
  justify-content:center;
  gap: 35px;
}

.score-container{
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
}

.score-text{
  margin: 0;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
}

.emoji-grid {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  width: max-content;
  font-size: 3em;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
}

.emoji-grid span{
  display: inline-block;
}

.emoji-grid span:not(:last-child) {
  margin-bottom: -12px;
}

.board-info {
  margin-top: -10px;
  height: 100%;
}

.button-container{
  display: flex;
  justify-content: stretch;
  gap: 10px;
}

.share-btn {
  padding: 6px 18px;
  flex-grow: 1;
}

.close-btn {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 32px;
  right: 32px;
  cursor: pointer;
  border-radius: 8px;
}

.close-btn:hover {
  background: #414158;
}

.close-btn > * {
  background: white;
  width: 18px;
  height: 2px;
  position: absolute;
  top: calc(50% - 1px);
  left: calc(50% - 10px);
}

.close-btn > *:first-child {
  transform: rotateZ(45deg);
}

.close-btn > *:last-child {
  transform: rotateZ(-45deg);
}

.accordian-panel {
  padding: 0;
  padding-top: 10px;
  border: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.accordian-header {
  display: flex;
  width: 100%;
  max-width: 435px;
  align-items: center;
  justify-content: space-between;
  font-size: 1.25rem;
  font-weight: 600;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  margin: 0;
  box-sizing: border-box;
  text-align: center;
  border-radius: 8px;
}

.accordian-header:hover {
  background: #414158;
}

.new-header {
  display: flex;
}

.new-label {
  display: flex;
  margin-left: 12px;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  color: #006600;
  background: #a7ffa7;
  padding: 2px 6px 2px 6px;
  border-radius: 12px;
}

.chevron {
  display: inline-block;
  text-align: center;
  font-size: 1.6rem;
  transition: transform 0.3s ease;
  transform: rotate(180deg);
}

.chevron-up {
  transform: rotate(0deg);
}

.accordian-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.6s ease;
  transform-origin: top;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: scaleY(0);
  max-height: 0;
  opacity: 0;
}

.slide-fade-enter-to,
.slide-fade-leave-from {
  transform: scaleY(1);
  max-height: 700px;
  opacity: 1;
}

@media (min-width: 769px) {
  .accordian-panel {
    min-width: 600px;
  }
}

@media (max-width: 768px) {
  .popular-answer-board {
    height: 20%;
    width: 80%;
    padding-left: 150px;
  }
  .content {
    max-width: 355px;
  }
  .game-summary-come-back-msg{
    font-size: 15px;
    margin: 1rem 0;
  }
  .stats-container{
    flex-direction: column;
    align-items: center;
    gap: 0;
    margin-bottom: 12px;
  }
  .rank-rank, .score-rank{
    font-size: 16px;
  }

  .board-title, .game-summary-title {
    font-size: 1.2rem;
  }

  .section-title{
    font-size: 1.7rem;
    margin: 30px 0;
  }

  .close-btn {
    top: 18px;
    right: 18px;
  }

  .accordian-header {
    font-size: 1rem;
    padding: 8px;
  }

  .new-label {
    font-size: .75rem;
  }
}

@media (max-width: 480px) {
  .popular-answer-board {
    padding-left: 30px;
  }
  .game-over .content .popular-answer-board {
    height: 20%;
    width: 80%;
    padding-left: 30px;
  }
  .content {
    max-width: 255px;
  }
  .board-title, .game-summary-title {
    font-size: 1.2rem;
  }

  .score-text{
    font-size: 1.2rem;
  }

  .section-title{
    font-size: 1.4rem;
    margin: 20px 0;
  }

  .rank-rank, .score-rank{
    font-size: 14px;
  }

  .close-btn {
    top: 18px;
    right: 18px;
  }

  .accordian-header {
    font-size: .9rem;
    padding: 8px;
  }

  .new-label {
    font-size: .7rem;
  }
}

</style>